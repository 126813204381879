import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { first } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';

import { ProfileOptionComponent } from '../profile-option/profile-option.component';
import { InputComponent } from 'app/shared/components/input/input.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';
import { SuccessToastComponent } from 'app/shared/components/success-toast/success-toast.component';

import { IProfileOption, IUserPreferences } from 'app/interfaces/profile.interfaces';
import { IInputCustomConfig } from 'app/shared/interfaces/input.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { IToastConfig } from 'app/shared/interfaces/toast-config.interfaces';

import { EProfileState } from 'app/enums/profile.enums';
import { EInputSupportMessage, EInputCustomType, EInputUsage } from 'app/shared/enums/input.enums';
import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';

@Component({
  selector: 'stxt-preferences',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileOptionComponent,
    InputComponent,
    ButtonComponent,
    SuccessToastComponent
  ],
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent implements OnChanges {
  @Input() interestsData: string[];
  @Input() genderData: string;
  @Input() name: string;

  identifyOptions: IProfileOption[] = [
    { label: 'Male', value: 'male', selected: false },
    { label: 'Female', value: 'female', selected: false },
    { label: 'Transgender', value: 'transgender', selected: false }
  ];
  interestsOptions: IProfileOption[] = [
    { label: 'Women', value: 'women', selected: false },
    { label: 'Men', value: 'men', selected: false },
    { label: 'Trans women', value: 'trans_women', selected: false },
    { label: 'Trans men', value: 'trans_men', selected: false }
  ];
  nameInput: IInputCustomConfig = {
    placeholder: 'Introduce yourself',
    supportingText: {
      text: EInputSupportMessage.FieldRequired
    },
    type: EInputCustomType.Text,
    usageCase: EInputUsage.Page
  };
  selectedInterests: string[] = [];
  selectedGender: string;
  userId = localStorage.getItem('userId');
  preferencesForm: FormGroup = new FormGroup({
    name: new FormControl('')
  });
  buttonConfig: IButtonConfig = {
    text: 'Save changes',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  toast: IToastConfig = {
    toastHeading: 'Success',
    toastSubheading: 'Preferences updated successfully'
  };
  public isToastMessage: boolean = false;

  constructor(
    private profileService: ProfileService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnChanges(): void {
    this.genderData ? (this.selectedGender = this.genderData) : '';
    this.interestsData ? (this.selectedInterests = this.interestsData) : [];
    this.setSelectedOption(this.genderData);
    this.setSelectedInterests(this.interestsData);
    this.preferencesForm.patchValue({ name: this.name });
  }

  selectGender(selectedOption: IProfileOption): void {
    if (selectedOption.selected) return;
    selectedOption.selected = true;
    this.identifyOptions.forEach(option => {
      if (option !== selectedOption) {
        option.selected = false;
      }
    });
    this.selectedGender = selectedOption.value;
    this.analyticsService.onboardingProfile(
      EProfileState.PROFILE_IDENTIFY,
      this.userId,
      null,
      null
    );
  }

  toggleInterest(selectedOption: IProfileOption): void {
    selectedOption.selected = !selectedOption.selected;
    if (selectedOption.selected) {
      this.selectedInterests.push(selectedOption.value);
    } else {
      this.selectedInterests = this.selectedInterests.filter(
        interest => interest !== selectedOption.value
      );
    }
  }

  setSelectedOption(gender: string): void {
    this.identifyOptions.forEach(option => {
      option.selected = option.value === gender?.toLowerCase();
    });
  }

  setSelectedInterests(interests: string[]): void {
    this.interestsOptions.forEach(option => {
      option.selected = interests?.includes(option.value?.toLowerCase());
    });
  }

  saveProfilePreferences(): void {
    const userPreferences: IUserPreferences = {
      gender: this.selectedGender?.toLowerCase(),
      interests: this.selectedInterests,
      name: this.preferencesForm.value.name
    };
    this.checkAnalytics(userPreferences);
    this.profileService
      .updateProfileData<IUserPreferences>(userPreferences)
      .pipe(first())
      .subscribe(() => {
        this.isToastMessage = true;
      });
  }

  checkAnalytics(userPreferences: IUserPreferences): void {
    const analyticsMap = {
      name: { condition: Boolean(userPreferences.name), state: EProfileState.PROFILE_ASK_NAME },
      interests: {
        condition: userPreferences.interests.length > 0,
        state: EProfileState.PROFILE_INTERESTS
      },
      gender: { condition: Boolean(userPreferences.gender), state: EProfileState.PROFILE_IDENTIFY }
    };

    Object.values(analyticsMap)
      .filter(({ condition }) => condition)
      .forEach(({ state }) => {
        this.analyticsService.onboardingProfile(state, this.userId, null, null);
      });
  }
}
