import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EDiscoverCardState } from '../../../enums/discover.enums';

@Component({
  selector: 'stxt-discover-card-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './discover-card-badge.component.html',
  styleUrl: './discover-card-badge.component.scss'
})
export class DiscoverCardBadgeComponent {
  @Input() badge?: EDiscoverCardState;
}
