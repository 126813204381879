import { z as t } from "zod";
import { v4 as xe } from "uuid";
import { connectToChild as me } from "penpal";
import F from "chalk";
const Ue = "https://cde.getopenpay.com",
  $e = t.object({
    type: t.string(),
    loc: t.array(t.string()),
    msg: t.string(),
    url: t.string()
  }),
  qe = t.array($e),
  ze = t.object({
    code: t.string(),
    message: t.string(),
    path: t.array(t.string())
  }),
  Ve = t.array(ze),
  He = {
    "failed to fetch": "Connection error: please double check if you are connected to the internet, then try again"
  },
  B = n => {
    const e = n.toLowerCase().trim();
    return He[e] ?? n;
  },
  Be = n => {
    try {
      return typeof JSON.parse(n) == "object";
    } catch {
      return !1;
    }
  },
  Ke = n => n.length === 1 ? B(n[0].msg) : `Please fix the following errors:
${n.map(o => `• ${B(o.msg)}`).join(`
`)}`,
  Ye = n => `Encountered the following errors:
${n.map(o => `- ${o.path.join("/")}: ${o.message}`).join(`
`)}`,
  pe = n => {
    if (n instanceof Error) if (Be(n.message)) {
      const e = JSON.parse(n.message);
      return qe.safeParse(e).success ? Ke(e) : Ve.safeParse(e).success ? Ye(e) : (console.warn(`Encountered unknown stringified JSON object:
${n.message}`), n.message);
    } else return B(n.message);
    if (typeof n == "object") try {
      return JSON.stringify(n);
    } catch (e) {
      return e + "";
    } else return n + "";
  },
  R = (n, e) => (...o) => {
    try {
      return e(...o);
    } catch (r) {
      console.error(`[form] Error running callback (${n}):`, r);
      return;
    }
  },
  b = t.string().trim().min(1, {
    message: "Cannot be blank"
  }),
  E = t.string().trim().optional(),
  D = n => t.nullable(n.optional());
var m = /* @__PURE__ */(n => (n.FIRST_NAME = "firstName", n.LAST_NAME = "lastName", n.EMAIL = "email", n.ZIP_CODE = "zipCode", n.CITY = "city", n.STATE = "state", n.COUNTRY = "country", n.ADDRESS = "address", n.PHONE = "phone", n.PROMOTION_CODE = "promotionCode", n))(m || {});
const fe = t.nativeEnum(m);
var ye = /* @__PURE__ */(n => (n.CARD_NUMBER = "cardNumber", n.CARD_EXPIRY = "cardExpiry", n.CARD_CVC = "cardCvc", n))(ye || {});
const he = t.nativeEnum(ye),
  _e = t.union([fe, he]);
var ge = /* @__PURE__ */(n => (n.CARD = "card", n.CARD_NUMBER = "card-number", n.CARD_EXPIRY = "card-expiry", n.CARD_CVC = "card-cvc", n))(ge || {});
const Je = t.nativeEnum(ge),
  $ = t.object({
    provider: t.string(),
    processor_name: D(t.string()),
    metadata: D(t.record(t.string(), t.any()))
  }),
  Ze = t.object({
    id: t.string()
  });
t.object({
  cardNumber: E,
  expiry: E,
  cvc: E
});
t.object({
  backgroundColor: E,
  color: E,
  fontFamily: E,
  fontSize: E,
  fontWeight: E,
  margin: E,
  padding: E,
  letterSpacing: E,
  lineHeight: E
});
const S = t.enum([
  // Element -> Form
  "LAYOUT", "LOADED", "BLUR", "FOCUS", "CHANGE", "TOKENIZE_STARTED", "CHECKOUT_STARTED", "PAYMENT_FLOW_STARTED", "TOKENIZE_SUCCESS", "CHECKOUT_SUCCESS", "LOAD_ERROR", "VALIDATION_ERROR", "TOKENIZE_ERROR", "CHECKOUT_ERROR", "SETUP_PAYMENT_METHOD_SUCCESS",
  // Form -> Element
  "TOKENIZE", "CHECKOUT", "START_PAYMENT_FLOW"]),
  Ge = S.extract(["TOKENIZE_STARTED", "CHECKOUT_STARTED"]),
  We = t.object({
    type: Ge
  }),
  Qe = S.extract(["BLUR", "FOCUS", "CHANGE"]),
  Xe = t.object({
    type: Qe,
    elementType: he,
    errors: t.array(t.string()).optional()
  }),
  et = S.extract(["LOAD_ERROR", "TOKENIZE_ERROR", "CHECKOUT_ERROR"]),
  tt = t.object({
    type: et,
    message: b,
    headers: t.record(t.string(), t.string()).optional()
  }),
  nt = S.extract(["VALIDATION_ERROR"]),
  ot = t.object({
    type: nt,
    elementType: _e,
    errors: t.array(t.string())
  }),
  rt = t.object({
    type: t.literal(S.enum.LAYOUT),
    height: b
  }),
  st = t.object({
    type: t.literal(S.enum.LOADED),
    sessionId: b,
    totalAmountAtoms: t.number(),
    currency: E,
    checkoutPaymentMethods: t.array($)
  }),
  it = t.object({
    type: t.literal(S.enum.PAYMENT_FLOW_STARTED),
    nextActionMetadata: t.record(t.string(), t.any()),
    paymentFlowMetadata: t.any().optional(),
    startPFMetadata: t.optional(t.record(t.string(), t.any()))
  }),
  at = t.object({
    type: t.literal(S.enum.SETUP_PAYMENT_METHOD_SUCCESS),
    paymentMethodId: t.string()
  }),
  M = t.object({
    firstName: b,
    lastName: b,
    email: b,
    zipCode: b,
    country: b,
    promotionCode: E
  }),
  ct = t.object({
    email: b,
    zipCode: b,
    country: b,
    promotionCode: E
  }),
  lt = S.extract(["TOKENIZE", "CHECKOUT", "START_PAYMENT_FLOW"]),
  ut = t.object({
    type: lt,
    sessionId: b,
    checkoutPaymentMethod: $,
    paymentFlowMetadata: t.any().optional(),
    doNotUseLegacyCCFlow: t.boolean().optional(),
    existingCCPMId: E
  }).extend(M.shape),
  dt = t.object({
    type: t.literal(S.enum.TOKENIZE_SUCCESS),
    isReadyForCheckout: t.boolean()
  }),
  mt = t.object({
    type: t.literal(S.enum.CHECKOUT_SUCCESS),
    invoiceUrls: t.array(t.string()),
    subscriptionIds: t.array(t.string()),
    customerId: t.string()
  }),
  pt = t.discriminatedUnion("type", [We, Xe, tt, ot, rt, st, ut, dt, mt, it, at]),
  ft = t.object({
    payload: pt,
    nonce: b,
    formId: b,
    elementId: b
  });
t.object({
  amountAtom: t.number(),
  currency: b
});
t.object({
  secure_token: t.string(),
  promotion_code: t.string().optional()
});
t.object({
  secure_token: t.string(),
  existing_cc_pm_id: D(t.string())
});
const yt = t.object({
    payment_methods: t.array(Ze)
  }),
  ht = t.object({
    elementType: t.string(),
    errors: t.array(t.string())
  });
t.object({
  session_id: t.string()
});
const _t = t.object({
    success: t.literal(!1),
    error_type: t.literal("validation_error"),
    errors: t.array(ht)
  }),
  gt = t.discriminatedUnion("success", [t.object({
    success: t.literal(!0)
  }), _t]);
t.object({
  session_id: t.string(),
  checkout_payment_method: $,
  non_cde_form_fields: M,
  do_not_use_legacy_cc_flow: t.boolean().optional(),
  existing_cc_pm_id: D(t.string())
});
t.object({
  session_id: t.string(),
  checkout_payment_method: $,
  non_cde_form_fields: M
});
var x = /* @__PURE__ */(n => (n.SUCCESS = "success", n.FAILURE = "failure", n.CANCELLED = "cancelled", n))(x || {});
const wt = t.object({
    status: t.enum(["success", "failure", "cancelled"
    /* CANCELLED */])
  }),
  Et = t.object({
    type: t.string(),
    redirect_url: t.string(),
    initial_intent_id: t.string(),
    consent_id: t.string()
  }),
  bt = n => {
    const e = {};
    return Object.entries(n).forEach(([o, r]) => {
      try {
        const s = fe.parse(o);
        Array.isArray(r) ? e[s] = r : Array.isArray(r._errors) && (e[s] = r._errors);
      } catch {
        return;
      }
    }), e;
  },
  Ct = t.object({
    stripe_pk: t.string(),
    client_secret: t.string(),
    stripe_pm_id: t.string()
  }),
  St = t.object({
    type: t.string(),
    client_secret: t.string(),
    stripe_pk: t.string()
  }),
  Pt = {
    usd: "usd",
    brl: "brl"
  },
  q = async n => {
    for (let o = 0; o < 10; o++) ee() || (await Ot(500));
    if (!ee()) throw console.log("Stripe JS not found."), new Error("Stripe JS not found.");
    return new Stripe(n);
  },
  kt = async (n, e) => {
    const o = await q(n);
    return {
      elements: o.elements(e),
      stripe: o
    };
  },
  vt = async (n, e, o, r, s) => {
    console.log(s);
    const i = await q(n),
      a = Pt[o.toLowerCase().trim()];
    return i.paymentRequest({
      // TODO: replace this with stripe account country as soon as we support it
      country: "US",
      currency: a,
      total: {
        label: "Total",
        amount: e,
        pending: r
      },
      requestPayerName: !0,
      requestPayerEmail: !0,
      disableWallets: []
    });
  },
  Ot = n => new Promise(e => {
    setTimeout(e, n);
  }),
  ee = () => "Stripe" in window,
  Rt = async n => new Promise((e, o) => {
    try {
      n.on("paymentmethod", async r => {
        e(r);
      }), n.on("cancel", () => {
        o(new Error("Payment cancelled, please click Submit again to pay"));
      });
    } catch (r) {
      o(r);
    }
  }),
  At = async (n, e) => {
    if (!n.stripe_pk || !n.client_secret) throw new Error(`Invalid next action metadata format: ${JSON.stringify(n)}`);
    const r = await (await q(n.stripe_pk)).confirmCardSetup(n.client_secret, {
      payment_method: e.paymentMethod.id
    });
    if (r.error) throw e.complete("fail"), new Error(`Payment failed: ${r.error.message ?? "unknown"}`);
    return e.complete("success"), r;
  },
  It = async n => {
    var s, i, a, c;
    const o = await (await q(n.stripe_pk)).confirmCardSetup(n.client_secret, {
        payment_method: n.stripe_pm_id
      }),
      r = (s = o.setupIntent) == null ? void 0 : s.status;
    if (r === "succeeded") console.log("[3DS] CONFIRMING PM:", n.stripe_pm_id), console.log("[3DS] Setup intent created:", o.setupIntent);else throw r === "canceled" ? new Error("Payment cancelled, please click Submit again to pay") : new Error(`${((i = o.error) == null ? void 0 : i.message) ?? ((c = (a = o.setupIntent) == null ? void 0 : a.last_setup_error) == null ? void 0 : c.message) ?? "Payment failed, please click submit again to pay."}`);
  },
  Mt = n => JSON.stringify(n),
  K = (n, e) => Array.from(n.querySelectorAll("[data-opid]") ?? []).reduce((s, i) => {
    const a = i.getAttribute("data-opid");
    return a ? {
      ...s,
      [a]: i.value
    } : s;
  }, {}),
  Tt = n => {
    try {
      return ft.parse(n);
    } catch (e) {
      throw console.error("Error parsing event payload:", n, e), e;
    }
  },
  jt = (n, e, o, r, s) => {
    const i = {
      payload: r,
      nonce: xe(),
      formId: e,
      elementId: o
    };
    console.log(`[form ${e}] Sending event to child ${o}:`, i), n.postMessage(JSON.stringify(i), s);
  },
  Ft = async n => {
    if (typeof n != "object" || !n) return !1;
    try {
      const e = {
          type: "ping"
        },
        o = await n.send(e);
      if (o !== !0) throw new Error(`Expected 'true' after ping, got ${JSON.stringify(o)}`);
      return !0;
    } catch (e) {
      return console.error("Invalid CDE connection check:", e), !1;
    }
  },
  Lt = (n, e) => !!n && e,
  p = n => t.nullable(n.optional());
t.string().trim().min(1, {
  message: "Cannot be blank"
});
const Nt = t.object({
  cde_response_type: t.literal("error"),
  message: t.string(),
  headers: t.record(t.string(), t.string()).optional()
});
t.enum(["usd", "brl"]);
const we = t.object({
    name: t.string(),
    amount_atom_off: p(t.number()),
    percent_off: p(t.number()),
    currency: p(t.string()),
    duration: t.string(),
    duration_in_months: p(t.number())
  }),
  Ee = t.object({
    amount_subtotal_atom: t.number().int(),
    amount_total_atom: t.number().int(),
    currency: t.string(),
    description: p(t.string()),
    price_id: t.string(),
    billing_interval: p(t.string()),
    billing_interval_count: p(t.number().int()),
    quantity: t.number().int()
  }),
  Dt = t.record(t.string(), t.any()),
  xt = t.object({
    name: t.string(),
    settings: p(Dt)
  }),
  T = t.object({
    provider: t.string(),
    processor_name: p(t.string()),
    metadata: p(t.record(t.string(), t.any()))
  }),
  Ut = t.object({
    quantity: t.number()
  }),
  $t = t.object({
    id: p(t.string()),
    billing_interval: p(t.string()),
    billing_interval_count: p(t.number().int()),
    subscription_items: t.array(Ut),
    trial_start: p(t.string()),
    trial_end: p(t.string())
  }),
  be = t.object({
    amount_atom: t.number()
  }),
  qt = t.object({
    amount_atom: t.number(),
    amount_atom_considering_discount_applied: t.number(),
    currency: t.string(),
    discount_amount_atoms: t.array(be),
    subscription_item_id: t.string()
  }),
  zt = t.object({
    coupon: we
  }),
  Vt = t.object({
    currency: t.string(),
    discounts: t.array(zt),
    hosted_invoice_url: p(t.string()),
    lines: t.array(qt),
    remaining_amount_atom: t.number(),
    tax_amount_atom: t.number(),
    total_amount_atom: t.number(),
    total_discount_amount_atoms: t.array(be)
  }),
  te = t.object({
    created: t.array($t),
    invoices: t.array(Vt)
  }),
  Ce = t.object({
    preview: te,
    preview_post_trial: p(te),
    coupons: t.array(we)
  }),
  ne = t.object({
    status: t.literal("open"),
    token: t.string(),
    email: p(t.string()),
    line_items: t.array(Ee),
    amount_subtotal_atom: t.number().int(),
    amount_total_atom: t.number().int(),
    brand: xt,
    subs_preview: p(Ce),
    // Null in setup mode
    methods_available: t.array(T)
  }),
  Se = t.discriminatedUnion("mode", [t.object({
    mode: t.literal("setup"),
    currency: t.nullable(t.undefined())
  }).extend(ne.shape), t.object({
    mode: t.enum(["payment", "subscription"]),
    currency: t.string()
  }).extend(ne.shape)]),
  Ht = t.object({
    status: t.literal("complete")
  }),
  Bt = t.object({
    status: t.literal("expired")
  });
t.union([Se, Ht, Bt]);
t.object({
  logo: t.string(),
  icon: t.string(),
  primary_color: t.string(),
  secondary_color: t.string()
});
const Pe = t.object({
  invoice_urls: t.array(t.string()),
  subscription_ids: t.array(t.string()),
  customer_id: t.string()
});
t.object({
  secure_token: t.string(),
  payment_input: t.any(),
  // Should follow AnyPaymentInput from CDE
  customer_email: t.string(),
  customer_zip_code: t.string(),
  customer_country: t.string(),
  line_items: t.array(Ee),
  total_amount_atom: t.number().int(),
  cancel_at_end: t.boolean(),
  checkout_payment_method: T,
  promotion_code: t.string().optional(),
  do_not_use_legacy_cc_flow: t.boolean().optional()
});
const Kt = t.object({
  payment_method_id: t.string()
});
t.object({
  id: t.string(),
  return_url: t.string()
});
const Yt = t.object({
  type: t.string(),
  loc: t.array(t.string()),
  msg: t.string(),
  url: t.string()
});
t.array(Yt);
t.object({
  new_customer_email: t.string().optional(),
  new_customer_address: t.record(t.string(), t.any()).optional(),
  payment_provider: t.string(),
  checkout_payment_method: T,
  existing_cc_pm_id: t.string().optional(),
  their_existing_pm_id: t.string().optional()
});
const ke = t.object({
  required_user_actions: t.array(t.record(t.string(), t.any()))
});
t.object({
  fields: ct,
  checkoutPaymentMethod: T
});
t.object({
  session_id: t.string(),
  non_cde_form_fields: M,
  checkout_payment_method: T
});
const Jt = t.object({
  required_user_actions: t.array(t.record(t.string(), t.any())),
  cc_pm_id: t.string()
});
t.record(t.any());
const Zt = n => n.reduce((e, o) => e + o, 0);
function Gt(n, e) {
  var o = Object.setPrototypeOf;
  o ? o(n, e) : n.__proto__ = e;
}
function Wt(n, e) {
  e === void 0 && (e = n.constructor);
  var o = Error.captureStackTrace;
  o && o(n, e);
}
var Qt = /* @__PURE__ */function () {
    var n = function (o, r) {
      return n = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (s, i) {
        s.__proto__ = i;
      } || function (s, i) {
        for (var a in i) Object.prototype.hasOwnProperty.call(i, a) && (s[a] = i[a]);
      }, n(o, r);
    };
    return function (e, o) {
      if (typeof o != "function" && o !== null) throw new TypeError("Class extends value " + String(o) + " is not a constructor or null");
      n(e, o);
      function r() {
        this.constructor = e;
      }
      e.prototype = o === null ? Object.create(o) : (r.prototype = o.prototype, new r());
    };
  }(),
  Xt = function (n) {
    Qt(e, n);
    function e(o, r) {
      var s = this.constructor,
        i = n.call(this, o, r) || this;
      return Object.defineProperty(i, "name", {
        value: s.name,
        enumerable: !1,
        configurable: !0
      }), Gt(i, s.prototype), Wt(i), i;
    }
    return e;
  }(Error);
class ve extends Xt {
  constructor(e) {
    const o = `[cde-client] Error querying CDE: ${e.message}`;
    super(o), this.response = e;
  }
  get originalErrorMessage() {
    return this.response.message;
  }
}
const P = async (n, e, o) => {
    console.log("[cde-client] Querying CDE with path and connection:", e.type, n);
    const r = await n.send(e);
    if (console.log("[cde-client] Got response from CDE:", r), tn(r)) throw new ve(r);
    if (!en(r, o)) {
      const s = o.safeParse(r);
      throw s.success ? new Error("Invalid state") : (console.error("OJS queryApi got a schema error. Expected schema:", o, "Actual:", r), s.error);
    }
    return r;
  },
  en = (n, e) => e.safeParse(n).success,
  tn = n => Nt.safeParse(n).success,
  nn = async (n, e) => await P(n, {
    type: "get_checkout_preview",
    payload: e
  }, Ce),
  j = async n => await P(n, {
    type: "get_prefill",
    payload: {}
  }, Se),
  on = async (n, e) => await P(n, {
    type: "v2_start_payment_flow",
    payload: e
  }, ke),
  rn = async (n, e) => await P(n, {
    type: "start_payment_flow_for_cc",
    payload: e
  }, Jt),
  sn = async (n, e) => await P(n, {
    type: "start_payment_flow_for_pr",
    payload: e
  }, ke),
  Z = async (n, e) => await P(n, {
    type: "confirm_payment_flow",
    payload: e
  }, yt),
  an = async (n, e, o) => {
    const r = await nn(n, {
        secure_token: e,
        promotion_code: o
      }),
      s = new Set(r.preview.invoices.map(c => c.currency));
    if (s.size !== 1) throw new Error(`Expected exactly one currency, got ${s.size}`);
    const i = s.values().next().value ?? "usd",
      a = Zt(r.preview.invoices.map(c => c.remaining_amount_atom));
    return {
      currency: i,
      amountAtom: a
    };
  },
  Oe = async (n, e, o, r) => {
    if (o) return {
      amountAtom: 0,
      currency: "usd"
    };
    {
      r = r || void 0;
      const s = await an(n, e, r);
      return {
        amountAtom: s.amountAtom,
        currency: s.currency
      };
    }
  },
  cn = async (n, e) => {
    if (n.size === 0) throw new Error("No CDE connections found");
    return await Promise.all(Array.from(n.values()).map(r => P(r, {
      type: "tokenize_card",
      payload: e
    }, gt)));
  },
  oe = async (n, e) => {
    try {
      return await P(n, {
        type: "checkout_card_elements",
        payload: e
      }, Pe);
    } catch (o) {
      throw console.error("[cde-client] Error during checkoutCardElements:", o, JSON.stringify(o)), o;
    }
  },
  ln = async (n, e) => await P(n, {
    type: "setup_payment_method",
    payload: e
  }, Kt),
  Re = async (n, e) => await P(n, {
    type: "checkout",
    payload: e
  }, Pe),
  Ae = 1e3,
  un = async (n, e) => {
    console.log(e);
    const r = await me({
      iframe: n,
      debug: !0,
      timeout: Ae,
      childOrigin: "*"
      // TODO: use with proper childOrigin
    }).promise;
    return (await P(r, {
      type: "ping-3ds-status"
    }, wt)).status;
  },
  G = n => async e => {
    try {
      e.flowCallbacks.onCheckoutStarted();
      const o = await n(e);
      if (o.mode === "setup") e.flowCallbacks.onSetupPaymentMethodSuccess(o.result.payment_method_id);else if (o.mode === "checkout") e.flowCallbacks.onCheckoutSuccess(o.result.invoice_urls, o.result.subscription_ids, o.result.customer_id);else throw new Error(`Unhandled mode: ${o}`);
    } catch (o) {
      throw e.flowCallbacks.onCheckoutError(pe(o)), o;
    }
  },
  Ie = n => async e => {
    try {
      return await n(e);
    } catch (o) {
      const {
        err__: r
      } = k("init-error-catcher");
      return r(o), {
        isAvailable: !1,
        reason: pe(o)
      };
    }
  },
  k = n => {
    const e = (...r) => {
        window.console.log(`${F.green.bold("ojs/")}${F.bold.gray(`${n}`)}	`, ...r);
      },
      o = (...r) => {
        window.console.error(`${F.red.bold("ojs/")}${F.bold.gray(`${n}`)}	`, ...r);
      };
    return {
      log: e,
      err: o,
      log__: e,
      err__: o
    };
  },
  {
    log__: no,
    err__: dn
  } = k("common"),
  W = n => {
    if (n.payment_methods.length !== 1) throw new Error(`Expected exactly one payment method, got ${n.payment_methods.length}`);
    return {
      payment_method_id: n.payment_methods[0].id
    };
  },
  Me = (n, e) => {
    const o = n.find(r => e.safeParse(r).success);
    if (!o) throw dn("No CPMs found for model. All models:", n), new Error("No CPMs found for model");
    return e.parse(o);
  },
  {
    log__: mn,
    err__: Te
  } = k("common-cc"),
  Q = (n, e) => {
    const o = M.safeParse(n);
    if (!o.success) {
      const r = o.error.format(),
        s = bt(r);
      for (const [i, a] of Object.entries(s)) e(i, a, i);
      throw Te("Got validation errors in non-CDE form fields", s), new Error("Got validation errors in non-CDE form fields");
    }
    return o.data;
  },
  pn = (n, e) => {
    for (const o of n) if (o.success === !1) throw o.errors.forEach(r => {
      const s = _e.safeParse(r.elementType);
      if (!s.success) Te(`Unknown field name in onValidationError: ${r.elementType}`);else {
        const i = s.data;
        e(i, r.errors);
      }
    }), mn(`Error tokenizing card: got validation errors: ${JSON.stringify(o.errors)}`), new Error("Got validation errors while tokenizing card");
  },
  re = "ojs-stripe-link-btn",
  {
    log__: w,
    err__: fn
  } = k("stripe-link"),
  yn = t.object({
    provider: t.literal("stripe_link"),
    processor_name: t.literal("stripe"),
    metadata: t.object({
      stripe_pk: t.string()
    })
  }),
  hn = t.array(t.object({
    our_existing_pm_id: t.string()
  })).length(1),
  _n = Ie(async ({
    context: n,
    flowCallbacks: e
  }) => {
    const o = n.customInitParams.stripeLink;
    w("Checking if there are any CPMs for Stripe PR...");
    const r = Me(n.checkoutPaymentMethods, yn);
    w("Starting stripe link flow...");
    const s = Array.from(n.cdeConnections.values())[0],
      i = await j(s),
      a = i.mode === "setup";
    w("Creating stripe elements...");
    const c = await Oe(s, i.token, a, void 0),
      {
        elements: l,
        stripe: u
      } = await kt(r.metadata.stripe_pk, {
        mode: "setup",
        currency: c.currency,
        setup_future_usage: "off_session",
        paymentMethodCreation: "manual"
      });
    w("Creating express checkout...");
    const d = l.create("expressCheckout", {
        buttonHeight: o == null ? void 0 : o.buttonHeight,
        paymentMethods: {
          amazonPay: "never",
          applePay: "never",
          googlePay: "never",
          paypal: "never"
        }
      }),
      f = () => {
        d.mount(`#${re}`);
      },
      y = () => {
        d.unmount();
      };
    return o != null && o.doNotMountOnInit ? w("NOT mounting stripe link button (doNotMountOnInit is true)") : (w("Mounting stripe link button..."), f()), d.on("click", async _ => {
      if (w("Stripe Link button clicked"), o != null && o.overrideLinkSubmit && !(await o.overrideLinkSubmit())) {
        w("Stripe Link submit aborted by overrideLinkSubmit");
        return;
      }
      _.resolve();
    }), d.on("confirm", async _ => {
      w("Stripe Link window confirmed", _);
      const g = await u.createPaymentMethod({
        elements: l,
        params: {
          billing_details: _.billingDetails
        }
      });
      g.error ? (fn("error", g.error), e.onCheckoutError(g.error.message ?? "Stripe Link unknown error")) : (w("paymentMethod", g.paymentMethod), v.stripeLink.run({
        context: n,
        checkoutPaymentMethod: r,
        nonCdeFormInputs: K(n.formDiv),
        flowCallbacks: e,
        customParams: {
          stripePM: g.paymentMethod
        },
        initResult: {
          isAvailable: !0
        }
      }));
    }), {
      isAvailable: !0,
      controller: {
        mountButton: f,
        dismountButton: y,
        waitForButtonToMount: async () => await En(re)
      }
    };
  }),
  gn = G(async ({
    context: n,
    checkoutPaymentMethod: e,
    nonCdeFormInputs: o,
    flowCallbacks: r,
    customParams: s
  }) => {
    w("Running Stripe PR flow...");
    const i = Array.from(n.cdeConnections.values())[0];
    w("Merging PM fields with form fields...");
    const a = wn(o, s.stripePM),
      c = Q(a, r.onValidationError);
    w("Starting payment flow...");
    const l = await on(i, {
      payment_provider: e.provider,
      checkout_payment_method: e,
      their_existing_pm_id: s.stripePM.id
    });
    w("Start payment flow response", l);
    const d = hn.parse(l.required_user_actions)[0].our_existing_pm_id,
      f = await j(i);
    if (f.mode === "setup") {
      w("Doing payment setup...");
      const y = await Z(i, {
        secure_token: f.token,
        existing_cc_pm_id: d
      });
      return {
        mode: "setup",
        result: W(y)
      };
    } else {
      w("Doing checkout...");
      const y = {
        secure_token: f.token,
        payment_input: {
          provider_type: e.provider
        },
        customer_email: c[m.EMAIL],
        customer_zip_code: c[m.ZIP_CODE],
        customer_country: c[m.COUNTRY],
        promotion_code: c[m.PROMOTION_CODE],
        line_items: f.line_items,
        total_amount_atom: f.amount_total_atom,
        cancel_at_end: !1,
        checkout_payment_method: e
      };
      return {
        mode: "checkout",
        result: await Re(i, y)
      };
    }
  }),
  wn = (n, e) => {
    var a, c, l, u, d;
    const o = {
        ...n
      },
      [r, ...s] = ((c = (a = e.billing_details.name) == null ? void 0 : a.trim()) == null ? void 0 : c.split(/\s+/)) ?? [],
      i = s.join(" ") || void 0;
    return o[m.FIRST_NAME] = o[m.FIRST_NAME] || r || "_OP_UNKNOWN", o[m.LAST_NAME] = o[m.LAST_NAME] || i || "_OP_UNKNOWN", o[m.EMAIL] = o[m.EMAIL] || (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (l = e.link) == null ? void 0 : l.email) || e.billing_details.email || "op_unfilled@getopenpay.com", o[m.ZIP_CODE] = o[m.ZIP_CODE] || ((u = e.billing_details.address) == null ? void 0 : u.postal_code) || "00000", o[m.COUNTRY] = o[m.COUNTRY] || ((d = e.billing_details.address) == null ? void 0 : d.country) || "US", w("Final form inputs:", o), o;
  },
  En = n => new Promise(e => {
    const o = () => {
      const r = document.getElementById(n);
      r ? e(r) : requestAnimationFrame(o);
    };
    o();
  }),
  bn = "@keyframes fadeIn{0%{opacity:0;transform:scale(.9)}to{opacity:1;transform:scale(1)}}.overlay{position:fixed;top:0;left:0;width:100%;height:100%;z-index:2147483648!important;display:flex;justify-content:center;align-items:center;background-color:#00000080}.container{max-width:500px;max-height:600px;width:100%;height:100%;background-color:#fff;position:relative;animation:fadeIn .3s ease-out}.frame{max-width:500px;max-height:600px;width:100%;height:100%;border:none}.cancel-button{position:absolute;background:transparent;border:none;font-size:1rem;color:#fff;cursor:pointer;top:-1.5rem;padding:0;right:0}",
  Cn = n => {
    const e = document.createElement("template");
    e.innerHTML = `
    <style>
      ${bn}
    </style>
    <div class="overlay">
      <div class="container">
        <button class="cancel-button">Cancel</button>
        <iframe src="${n}" class="frame" id="three-ds-iframe" title="3D Secure verification" allow="payment"></iframe>
      </div>
    </div>
  `;
    const o = document.createElement("div"),
      r = o.attachShadow({
        mode: "open"
      });
    r.appendChild(e.content.cloneNode(!0));
    const s = r.querySelector("#three-ds-iframe"),
      i = r.querySelector(".cancel-button");
    return document.body.appendChild(o), {
      host: o,
      iframe: s,
      cancelButton: i
    };
  };
function Sn(n, e, o) {
  const r = async () => {
    try {
      console.log("🔄 Polling CDE connection...");
      const i = await un(n, o);
      i && (console.log("🟢 CDE connection successful! Stopping polling..."), console.log("➡️ Got status:", i), clearInterval(s), e(i));
    } catch {}
  };
  r();
  const s = setInterval(r, Ae);
  return s;
}
function Pn({
  elements: n,
  pollingInterval: e,
  resolve: o
}) {
  const r = () => {
    clearInterval(e), n.host.remove(), o(x.CANCELLED);
  };
  return n.cancelButton.addEventListener("click", r), () => {
    n.cancelButton.removeEventListener("click", r);
  };
}
async function kn({
  url: n,
  baseUrl: e
}) {
  const o = Cn(n);
  return new Promise(r => {
    const s = c => {
        setTimeout(() => {
          o.host.remove();
        }, 1e3), r(c), a == null || a();
      },
      i = Sn(o.iframe, s, new URL(e).origin),
      a = Pn({
        elements: o,
        pollingInterval: i,
        resolve: r
      });
  });
}
const {
    log__: h,
    err__: se
  } = k("common-cc"),
  {
    log__: ie
  } = k("stripe-cc"),
  vn = G(async ({
    context: n,
    checkoutPaymentMethod: e,
    nonCdeFormInputs: o,
    flowCallbacks: r
  }) => {
    var u;
    h("├ Running common CC flow...", {
      checkoutPaymentMethod: e
    });
    const s = Array.from(n.cdeConnections.values())[0],
      i = await j(s);
    h(`├ Validating non-CDE form fields [Mode: ${i.mode}]`);
    const a = Q(o, r.onValidationError);
    h(`├ Tokenizing card info in CDE [Session: ${n.elementsSessionId}]`);
    const c = await cn(n.cdeConnections, {
      session_id: n.elementsSessionId
    });
    pn(c, r.onValidationError);
    const l = {
      session_id: n.elementsSessionId,
      checkout_payment_method: e,
      non_cde_form_fields: a
    };
    try {
      if (i.mode === "setup") {
        h(`├ Setting up payment method in CDE [Token: ${i.token}]`);
        const d = await ln(s, l);
        return h(`╰ Setup completed successfully [PM ID: ${d.payment_method_id}]`), {
          mode: "setup",
          result: d
        };
      } else {
        h(`├ Initial checkout flow. Checking out card info in CDE [Session: ${n.elementsSessionId}]`);
        const d = await oe(s, l);
        return h("╰ Checkout completed successfully."), {
          mode: "checkout",
          result: d
        };
      }
    } catch (d) {
      if (d instanceof ve && d.originalErrorMessage === "3DS_REQUIRED") {
        h("├ Card requires 3DS, starting non-legacy payment flow"), h("error.response.headers", d.response.headers);
        const f = ((u = d.response.headers) == null ? void 0 : u["op-should-use-new-flow"]) === "true",
          y = await rn(s, l);
        if (h(`├ op-should-use-new-flow: ${f}`), h("├ Payment flow result:", y), f ? await An(y, n.baseUrl) : await In(y), i.mode === "setup") {
          h(`├ Confirming payment flow [cc_pm_id: ${y.cc_pm_id}]`);
          const _ = await Z(s, {
            secure_token: i.token,
            existing_cc_pm_id: y.cc_pm_id
          });
          h("├ Confirm payment flow result received Result:", _);
          const g = W(_);
          return h(`╰ Setup completed successfully [PM ID: ${g.payment_method_id}]`), {
            mode: "setup",
            result: g
          };
        } else {
          h(`├ Checking out after 3DS flow [Flow: ${f ? "new" : "legacy"}]`);
          const _ = await oe(s, {
            ...l,
            existing_cc_pm_id: y.cc_pm_id,
            do_not_use_legacy_cc_flow: !f
          });
          return h("╰ Checkout completed successfully."), {
            mode: "checkout",
            result: _
          };
        }
      }
      throw se("╰ Error checking out card info in CDE"), se(d), d;
    }
  }),
  On = n => {
    const e = n.required_user_actions.find(o => o.type === "stripe_3ds");
    if (!e) throw new Error("No stripe 3DS action found");
    return Ct.parse(e);
  },
  Rn = n => {
    const e = n.required_user_actions.find(o => o.type === "airwallex_payment_consent");
    if (!e) throw new Error("No common 3DS action found");
    return Et.parse(e);
  },
  An = async (n, e) => {
    const o = Rn(n);
    h(`├ Using common 3DS flow [URL: ${o.redirect_url}]`);
    const r = await kn({
      url: o.redirect_url,
      baseUrl: e
    });
    if (h(`╰ 3DS verification completed [Status: ${r}]`), r === x.CANCELLED) throw new Error("3DS verification cancelled");
    if (r === x.FAILURE) throw new Error("3DS verification failed");
  },
  In = async n => {
    const e = On(n);
    ie(`├ Using stripe 3DS flow [Client Secret: ${e.client_secret.substring(0, 8)}...]`), await It(e), ie("╰ Stripe 3DS flow completed successfully");
  },
  {
    log__: C,
    err__: Mn
  } = k("stripe-pr"),
  Tn = t.object({
    provider: t.union([t.literal("apple_pay"), t.literal("google_pay")]),
    processor_name: t.literal("stripe"),
    metadata: t.object({
      stripe_pk: t.string()
    })
  }),
  jn = Ie(async ({
    context: n
  }) => {
    C("Checking if there are any CPMs for Stripe PR...");
    const e = Me(n.checkoutPaymentMethods, Tn);
    C("Initializing Stripe PR flow...");
    const o = Array.from(n.cdeConnections.values())[0],
      r = await j(o),
      s = r.mode === "setup";
    C("Getting initial preview amount");
    const i = await Oe(o, r.token, s, void 0),
      a = await vt(e.metadata.stripe_pk, i.amountAtom, i.currency, s);
    C("Checking if can make payment");
    const c = await a.canMakePayment();
    return c === null ? (Mn("canMakePayment returned null"), {
      isAvailable: !1,
      reason: "canMakePayment returned null"
    }) : (C("Stripe PR flow initialized successfully. Can make payment", c), {
      isAvailable: !0,
      pr: a,
      availableProviders: {
        applePay: c.applePay,
        googlePay: c.googlePay
      }
    });
  }),
  Fn = G(async ({
    context: n,
    checkoutPaymentMethod: e,
    nonCdeFormInputs: o,
    flowCallbacks: r,
    customParams: s,
    initResult: i
  }) => {
    if (C("Running Stripe PR flow..."), s.provider !== e.provider) throw new Error(`Provider mismatch. Expected ${s.provider}, got ${e.provider}`);
    const a = Array.from(n.cdeConnections.values())[0],
      c = i.pr;
    if (s != null && s.overridePaymentRequest) {
      const g = s.overridePaymentRequest;
      C("Overriding PR amount with", g), Nn(c, g.amount, g.pending);
    }
    C("Showing PR dialog..."), c.show();
    const l = await Rt(c);
    C("Stripe PM added event:", l), C("Merging PM fields with form fields...");
    const u = Ln(o, l),
      d = Q(u, r.onValidationError);
    C("PR dialog finished. Starting payment flow...");
    const f = await sn(a, {
        fields: d,
        checkoutPaymentMethod: e
      }),
      y = Dn(f);
    C("Confirming PR with Stripe..."), await At(y, l);
    const _ = await j(a);
    if (_.mode === "setup") {
      C("Confirming payment flow for setup...");
      const g = await Z(a, {
        secure_token: _.token
      });
      return {
        mode: "setup",
        result: W(g)
      };
    } else {
      C("Performing checkout...");
      const g = {
        secure_token: _.token,
        payment_input: {
          provider_type: e.provider
        },
        customer_email: d[m.EMAIL],
        customer_zip_code: d[m.ZIP_CODE],
        customer_country: d[m.COUNTRY],
        promotion_code: d[m.PROMOTION_CODE],
        line_items: _.line_items,
        total_amount_atom: _.amount_total_atom,
        cancel_at_end: !1,
        checkout_payment_method: e
      };
      return {
        mode: "checkout",
        result: await Re(a, g)
      };
    }
  }),
  Ln = (n, e) => {
    var a, c, l, u;
    const o = {
        ...n
      },
      [r, ...s] = ((c = (a = e.payerName) == null ? void 0 : a.trim()) == null ? void 0 : c.split(/\s+/)) ?? [],
      i = s.join(" ") || void 0;
    return o[m.FIRST_NAME] = o[m.FIRST_NAME] || r || "_OP_UNKNOWN", o[m.LAST_NAME] = o[m.LAST_NAME] || i || "_OP_UNKNOWN", o[m.EMAIL] = o[m.EMAIL] || e.payerEmail || "op_unfilled@getopenpay.com", o[m.ZIP_CODE] = o[m.ZIP_CODE] || ((l = e.shippingAddress) == null ? void 0 : l.postalCode) || "00000", o[m.COUNTRY] = o[m.COUNTRY] || ((u = e.shippingAddress) == null ? void 0 : u.country) || "US", C("Final form inputs:", o), o;
  },
  Nn = (n, e, o) => {
    n.update({
      total: {
        amount: e.amountAtom,
        label: "Total",
        pending: o
      },
      currency: e.currency
    });
  },
  Dn = n => {
    if (n.required_user_actions.length !== 1) throw new Error(`Error occurred.
Details: got ${n.required_user_actions.length} required user actions`);
    return St.parse(n.required_user_actions[0]);
  },
  ae = (n, e, o) => {
    const r = n.filter(s => s.provider === e);
    if (r.length === 0) throw console.error("CPMs list", n), new Error(`No CPMs found with provider '${e}' and processor '${o}'`);
    if (r.length !== 1) throw console.error("CPMs list", n), new Error(`More than one CPM found with provider '${e}' and processor '${o}'`);
    return r[0];
  },
  v = {
    // ✋ Note: For flows that require initialization, please add them to `init-flows.ts`
    // Common
    commonCC: {
      run: vn
    },
    // Stripe
    stripePR: {
      init: jn,
      run: Fn
    },
    stripeLink: {
      init: _n,
      run: gn
    }
    // 👉 Add more flows here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  };
function xn(n) {
  return n && n.__esModule && Object.prototype.hasOwnProperty.call(n, "default") ? n.default : n;
}
var z = {};
Object.defineProperty(z, "__esModule", {
  value: !0
});
z.Observable = void 0;
const je = n => !!Symbol[n],
  X = n => je(n) ? Symbol[n] : "@@" + n,
  Un = X("iterator"),
  Y = X("observable"),
  Fe = X("species");
function U(n, e) {
  let o = n[e];
  if (o != null) {
    if (typeof o != "function") throw new TypeError(o + " is not a function");
    return o;
  }
}
function A(n) {
  let e = n.constructor;
  return e !== void 0 && (e = e[Fe], e === null && (e = void 0)), e !== void 0 ? e : V;
}
function $n(n) {
  return n instanceof V;
}
function O(n) {
  O.log ? O.log(n) : setTimeout(() => {
    throw n;
  });
}
function L(n) {
  Promise.resolve().then(() => {
    try {
      n();
    } catch (e) {
      O(e);
    }
  });
}
function Le(n) {
  let e = n._cleanup;
  if (e !== void 0 && (n._cleanup = void 0, !!e)) try {
    if (typeof e == "function") e();else {
      let o = U(e, "unsubscribe");
      o && o.call(e);
    }
  } catch (o) {
    O(o);
  }
}
function J(n) {
  n._observer = void 0, n._queue = void 0, n._state = "closed";
}
function qn(n) {
  let e = n._queue;
  if (e) {
    n._queue = void 0, n._state = "ready";
    for (let o = 0; o < e.length && (Ne(n, e[o].type, e[o].value), n._state !== "closed"); ++o);
  }
}
function Ne(n, e, o) {
  n._state = "running";
  let r = n._observer;
  try {
    let s = U(r, e);
    switch (e) {
      case "next":
        s && s.call(r, o);
        break;
      case "error":
        if (J(n), s) s.call(r, o);else throw o;
        break;
      case "complete":
        J(n), s && s.call(r);
        break;
    }
  } catch (s) {
    O(s);
  }
  n._state === "closed" ? Le(n) : n._state === "running" && (n._state = "ready");
}
function H(n, e, o) {
  if (n._state !== "closed") {
    if (n._state === "buffering") {
      n._queue.push({
        type: e,
        value: o
      });
      return;
    }
    if (n._state !== "ready") {
      n._state = "buffering", n._queue = [{
        type: e,
        value: o
      }], L(() => qn(n));
      return;
    }
    Ne(n, e, o);
  }
}
class zn {
  constructor(e, o) {
    this._cleanup = void 0, this._observer = e, this._queue = void 0, this._state = "initializing";
    let r = this,
      s = {
        get closed() {
          return r._state === "closed";
        },
        next(i) {
          H(r, "next", i);
        },
        error(i) {
          H(r, "error", i);
        },
        complete() {
          H(r, "complete");
        }
      };
    try {
      this._cleanup = o.call(void 0, s);
    } catch (i) {
      s.error(i);
    }
    this._state === "initializing" && (this._state = "ready");
  }
  get closed() {
    return this._state === "closed";
  }
  unsubscribe() {
    this._state !== "closed" && (J(this), Le(this));
  }
}
let V = class N {
  constructor(e) {
    if (!(this instanceof N)) throw new TypeError("Observable cannot be called as a function");
    if (typeof e != "function") throw new TypeError("Observable initializer must be a function");
    this._subscriber = e;
  }
  subscribe(e) {
    return (typeof e != "object" || e === null) && (e = {
      next: e,
      error: arguments[1],
      complete: arguments[2]
    }), new zn(e, this._subscriber);
  }
  forEach(e) {
    return new Promise((o, r) => {
      if (typeof e != "function") {
        r(new TypeError(e + " is not a function"));
        return;
      }
      function s() {
        i.unsubscribe(), o();
      }
      let i = this.subscribe({
        next(a) {
          try {
            e(a, s);
          } catch (c) {
            r(c), i.unsubscribe();
          }
        },
        error: r,
        complete: o
      });
    });
  }
  map(e) {
    if (typeof e != "function") throw new TypeError(e + " is not a function");
    let o = A(this);
    return new o(r => this.subscribe({
      next(s) {
        try {
          s = e(s);
        } catch (i) {
          return r.error(i);
        }
        r.next(s);
      },
      error(s) {
        r.error(s);
      },
      complete() {
        r.complete();
      }
    }));
  }
  filter(e) {
    if (typeof e != "function") throw new TypeError(e + " is not a function");
    let o = A(this);
    return new o(r => this.subscribe({
      next(s) {
        try {
          if (!e(s)) return;
        } catch (i) {
          return r.error(i);
        }
        r.next(s);
      },
      error(s) {
        r.error(s);
      },
      complete() {
        r.complete();
      }
    }));
  }
  reduce(e) {
    if (typeof e != "function") throw new TypeError(e + " is not a function");
    let o = A(this),
      r = arguments.length > 1,
      s = !1,
      a = arguments[1];
    return new o(c => this.subscribe({
      next(l) {
        let u = !s;
        if (s = !0, !u || r) try {
          a = e(a, l);
        } catch (d) {
          return c.error(d);
        } else a = l;
      },
      error(l) {
        c.error(l);
      },
      complete() {
        if (!s && !r) return c.error(new TypeError("Cannot reduce an empty sequence"));
        c.next(a), c.complete();
      }
    }));
  }
  async all() {
    let e = [];
    return await this.forEach(o => e.push(o)), e;
  }
  concat(...e) {
    let o = A(this);
    return new o(r => {
      let s,
        i = 0;
      function a(c) {
        s = c.subscribe({
          next(l) {
            r.next(l);
          },
          error(l) {
            r.error(l);
          },
          complete() {
            i === e.length ? (s = void 0, r.complete()) : a(o.from(e[i++]));
          }
        });
      }
      return a(this), () => {
        s && (s.unsubscribe(), s = void 0);
      };
    });
  }
  flatMap(e) {
    if (typeof e != "function") throw new TypeError(e + " is not a function");
    let o = A(this);
    return new o(r => {
      let s = [],
        i = this.subscribe({
          next(c) {
            if (e) try {
              c = e(c);
            } catch (u) {
              return r.error(u);
            }
            let l = o.from(c).subscribe({
              next(u) {
                r.next(u);
              },
              error(u) {
                r.error(u);
              },
              complete() {
                let u = s.indexOf(l);
                u >= 0 && s.splice(u, 1), a();
              }
            });
            s.push(l);
          },
          error(c) {
            r.error(c);
          },
          complete() {
            a();
          }
        });
      function a() {
        i.closed && s.length === 0 && r.complete();
      }
      return () => {
        s.forEach(c => c.unsubscribe()), i.unsubscribe();
      };
    });
  }
  [Y]() {
    return this;
  }
  static from(e) {
    let o = typeof this == "function" ? this : N;
    if (e == null) throw new TypeError(e + " is not an object");
    let r = U(e, Y);
    if (r) {
      let s = r.call(e);
      if (Object(s) !== s) throw new TypeError(s + " is not an object");
      return $n(s) && s.constructor === o ? s : new o(i => s.subscribe(i));
    }
    if (je("iterator") && (r = U(e, Un), r)) return new o(s => {
      L(() => {
        if (!s.closed) {
          for (let i of r.call(e)) if (s.next(i), s.closed) return;
          s.complete();
        }
      });
    });
    if (Array.isArray(e)) return new o(s => {
      L(() => {
        if (!s.closed) {
          for (let i = 0; i < e.length; ++i) if (s.next(e[i]), s.closed) return;
          s.complete();
        }
      });
    });
    throw new TypeError(e + " is not observable");
  }
  static of(...e) {
    let o = typeof this == "function" ? this : N;
    return new o(r => {
      L(() => {
        if (!r.closed) {
          for (let s = 0; s < e.length; ++s) if (r.next(e[s]), r.closed) return;
          r.complete();
        }
      });
    });
  }
  static get [Fe]() {
    return this;
  }
};
z.Observable = V;
Object.defineProperty(V, Symbol("extensions"), {
  value: {
    symbol: Y,
    hostReportError: O
  },
  configurable: !0
});
var Vn = z.Observable;
const Hn = /* @__PURE__ */xn(Vn),
  {
    log__: Bn,
    err__: Kn
  } = k("init-flows"),
  Yn = () => ("ojs_init_results" in window || (window.ojs_init_results = {}), window.ojs_init_results),
  Jn = (n, e) => {
    const o = {
      context: n,
      flowCallbacks: e
    };
    return console.log("[OJS] initializing OJS flows..."), {
      // Stripe PR
      stripePR: ce("stripePR", v.stripePR.init(o)),
      // Stripe Link
      stripeLink: ce("stripeLink", v.stripeLink.init(o))
      // 👉 Add initialization flows here
    };
  },
  ce = (n, e) => {
    const o = new Hn(r => {
      r.next({
        status: "loading"
      }), e.then(s => {
        r.next({
          status: "loaded",
          result: s
        });
      }).catch(s => {
        r.next({
          status: "error",
          message: s.message
        });
      });
    });
    return o.subscribe({
      next: r => {
        Bn(`${n} flow result`, r), Yn()[n] = r;
      },
      error: r => {
        throw Kn(`${n} flow initialization error:
${JSON.stringify(r)}`), r;
      }
    }), o;
  },
  le = {
    isLoading: !0,
    isAvailable: !1,
    startFlow: async () => {
      console.warn(`startFlow triggered while payment request is still not ready. 
      You can check the ".isLoading" param to know when a payment request is still loading, 
      and ".isAvailable" to know if a payment request is available.`);
    }
  },
  ue = {
    isLoading: !1,
    isAvailable: !1,
    startFlow: async () => {
      console.error("startFlow triggered when payment request is not available.");
    }
  };
class Zn {
  constructor(e) {
    this.formInstance = e, this.config = e.config, this.formId = e.formId, this.eventTargets = {}, this.nonces = /* @__PURE__ */new Set();
  }
  handleMessage(e) {
    var c;
    if (!e.source) throw new Error("No source found");
    if (e.origin !== ((c = this.formInstance.config._frameUrl) == null ? void 0 : c.origin) || typeof e.data == "object" && e.data.penpal) return;
    const o = Tt(JSON.parse(e.data));
    if (!this.validateEvent(o)) return;
    const {
        elementId: s,
        payload: i
      } = o,
      a = i.type;
    switch (a) {
      case "LAYOUT":
        this.handleLayoutEvent(i);
        break;
      case "FOCUS":
        this.handleFocusEvent(s, i.elementType);
        break;
      case "BLUR":
        this.handleBlurEvent(s, i.elementType);
        break;
      case "CHANGE":
        this.handleChangeEvent(s, i.elementType, i.errors);
        break;
      case "LOADED":
        this.handleLoadedEvent(e.source, s, i);
        break;
      case "LOAD_ERROR":
        this.handleLoadErrorEvent(i);
        break;
      case "VALIDATION_ERROR":
        this.handleValidationErrorEvent(i, s);
        break;
      default:
        console.warn("[form] Unhandled event type:", a);
    }
  }
  validateEvent(e) {
    return e.formId !== this.formInstance.formId || !e.elementId ? (console.warn("[form] Ignoring unknown event:", e), !1) : this.nonces.has(e.nonce) ? (console.warn("[form] Ignoring duplicate event:", e), !1) : (this.nonces.add(e.nonce), !0);
  }
  handleLayoutEvent(e) {
    const o = e.height ? `${e.height}px` : "100%";
    this.formInstance.setFormHeight(o);
  }
  handleFocusEvent(e, o) {
    this.config.onFocus && this.config.onFocus(e, o);
  }
  handleBlurEvent(e, o) {
    this.config.onBlur && this.config.onBlur(e, o);
  }
  handleChangeEvent(e, o, r) {
    this.config.onChange && this.config.onChange(e, o, r);
  }
  async handleLoadedEvent(e, o, r) {
    console.log("handleLoadedEvent is deprecated:", e, o, r), this.eventTargets[o] = e, this.formInstance.onCdeLoaded(r), this.config.onLoad && this.config.onLoad(r.totalAmountAtoms, r.currency);
  }
  handleLoadErrorEvent(e) {
    this.formInstance.config.onLoadError && this.formInstance.config.onLoadError(e.message);
  }
  handleValidationErrorEvent(e, o) {
    this.formInstance.config.onValidationError && this.formInstance.config.onValidationError(e.elementType, e.errors, o);
  }
  // async handleErrorEvent(payload: ErrorEventPayload) {
  //   if (payload.message === '3DS_REQUIRED') {
  //     const threeDSUrl = payload.headers?.['x-3ds-auth-url'] ?? SIMULATE_3DS_URL;
  //     // This will open a popup and process the 3DS flow
  //     // will return a status `success` | `failure` | `cancelled` which we can continue with
  //     const status = await start3dsVerification({ url: threeDSUrl, baseUrl: this.config.baseUrl! });
  //     console.log('🔐 3DS status:', status);
  //     // TODO: continue with status
  //     const cardCpm = this.formInstance.checkoutPaymentMethods?.find((cpm) => cpm.provider === 'credit_card');
  //     if (!this.formInstance.sessionId || !this.formInstance.formTarget || !this.config.onValidationError || !cardCpm)
  //       return;
  //     for (const [elementId, target] of Object.entries(this.eventTargets)) {
  //       if (!target) continue;
  //       const startPaymentFlowEvent = constructSubmitEventPayload(
  //         EventType.enum.START_PAYMENT_FLOW,
  //         this.formInstance.sessionId,
  //         document.querySelector(this.formInstance.formTarget) ?? document.body,
  //         this.config.onValidationError,
  //         { ...cardCpm, processor_name: 'stripe' },
  //         false
  //       );
  //       if (!startPaymentFlowEvent) continue;
  //       this.formInstance.checkoutFired = true;
  //       this.tokenizedData = startPaymentFlowEvent;
  //       this.postEventToFrame(target, elementId, startPaymentFlowEvent);
  //       break;
  //     }
  //   } else {
  //     this.formInstance.checkoutFired = false;
  //     if (this.formInstance.config.onCheckoutError) this.formInstance.config.onCheckoutError(payload.message);
  //   }
  // }
  // handleFormSubmit() {
  //   if (
  //     !this.formInstance.sessionId ||
  //     !this.formInstance.checkoutPaymentMethods?.length ||
  //     !this.config.onValidationError
  //   ) {
  //     return;
  //   }
  //   const cardCpm = this.formInstance.checkoutPaymentMethods.find((cpm) => cpm.provider === 'credit_card');
  //   if (!cardCpm) {
  //     throw new Error('Card not available as a payment method in checkout');
  //   }
  //   const tokenizeData = constructSubmitEventPayload(
  //     EventType.enum.TOKENIZE,
  //     this.formInstance.sessionId!,
  //     document.querySelector(this.formInstance.formTarget) ?? document.body,
  //     this.config.onValidationError,
  //     cardCpm,
  //     false
  //   );
  //   if (!tokenizeData) {
  //     throw new Error('Error constructing tokenize data');
  //   }
  //   for (const [elementId, target] of Object.entries(this.eventTargets)) {
  //     if (!target) continue;
  //     this.postEventToFrame(target, elementId, tokenizeData);
  //   }
  //   this.tokenizedData = tokenizeData;
  // }
  // async onUserCompletePaymentRequestUI(
  //   stripePm: PaymentRequestPaymentMethodEvent,
  //   checkoutPaymentMethod: CheckoutPaymentMethod
  // ): Promise<void> {
  //   if (!this.config.onValidationError || !this.formInstance.sessionId || !this.formInstance.checkoutPaymentMethods)
  //     return;
  //   for (const [elementId, element] of Object.entries(this.eventTargets ?? {})) {
  //     const paymentFlowMetadata = { stripePmId: stripePm.paymentMethod.id };
  //     const startPaymentFlowEvent = constructSubmitEventPayload(
  //       EventType.enum.START_PAYMENT_FLOW,
  //       this.formInstance.sessionId,
  //       document.querySelector(this.formInstance.formTarget) ?? document.body,
  //       this.config.onValidationError,
  //       checkoutPaymentMethod,
  //       false,
  //       paymentFlowMetadata
  //     );
  //     if (!startPaymentFlowEvent) continue;
  //     this.stripePm = stripePm;
  //     this.formInstance.checkoutFired = true;
  //     this.setTokenizedData(startPaymentFlowEvent);
  //     this.postEventToFrame(element, elementId, startPaymentFlowEvent);
  //     // emitEvent(element.node.contentWindow!, this.formId, elementId, startPaymentFlowEvent, this.config.baseUrl!);
  //     break;
  //   }
  // }
  postEventToFrame(e, o, r) {
    jt(e, this.formId, o, r, this.config.baseUrl);
  }
}
async function Gn(n, e) {
  const r = await me({
      iframe: n,
      debug: !1,
      childOrigin: e
    }).promise,
    s = await Ft(r);
  if (!Lt(r, s)) throw new Error("Got invalid CDE connection object");
  return r;
}
class Wn {
  constructor() {
    this.connections = /* @__PURE__ */new Map();
  }
  addConnection(e, o) {
    this.connections.set(e, o);
  }
  getConnection() {
    const e = this.connections.values().next().value;
    if (!e) throw new Error("Connection not found");
    return e;
  }
  getAllConnections() {
    return this.connections;
  }
}
const I = class I {
  constructor(e) {
    this.tryInitOjsFlows = () => {
      this.ojsFlowsInitialization === null && this.cdeLoadedPayload && this.connectionManager.getAllConnections().size !== 0 && (this.ojsFlowsInitialization = Jn(this.createOjsFlowContext(), this.createOjsFlowCallbacks()), this.ojsFlowsInitialization.stripePR.subscribe(o => this.onStripePRStatusChange(o)));
    }, this.onCdeLoaded = o => {
      this.cdeLoadedPayload || (this.cdeLoadedPayload = o, this.tryInitOjsFlows());
    }, this.onStripePRStatusChange = o => {
      var r, s, i, a, c, l;
      if (o.status === "loading") (s = (r = this.config).onPaymentRequestLoad) == null || s.call(r, {
        apple_pay: le,
        google_pay: le
      });else if (o.status === "error") (a = (i = this.config).onPaymentRequestLoad) == null || a.call(i, {
        apple_pay: ue,
        google_pay: ue
      });else if (o.status === "loaded") {
        const u = o.result,
          d = u.isAvailable && u.availableProviders.applePay,
          f = u.isAvailable && u.availableProviders.googlePay;
        (l = (c = this.config).onPaymentRequestLoad) == null || l.call(c, {
          apple_pay: {
            isLoading: !1,
            isAvailable: d,
            startFlow: async y => d ? this.submitPaymentRequest("apple_pay", u, y) : void 0
          },
          google_pay: {
            isLoading: !1,
            isAvailable: f,
            startFlow: async y => f ? this.submitPaymentRequest("google_pay", u, y) : void 0
          }
        });
      }
    }, this.submitPaymentRequest = (o, r, s) => {
      const i = this.createOjsFlowContext();
      return v.stripePR.run({
        context: i,
        checkoutPaymentMethod: ae(i.checkoutPaymentMethods, o),
        nonCdeFormInputs: K(i.formDiv),
        flowCallbacks: this.createOjsFlowCallbacks(),
        customParams: {
          provider: o,
          overridePaymentRequest: s == null ? void 0 : s.overridePaymentRequest
        },
        initResult: r
      });
    }, I.assignAsSingleton(this), this.config = {
      ...e,
      baseUrl: e.baseUrl ?? "https://cde.getopenpay.com"
    }, this.elements = null, this.formId = `opjs-form-${window.crypto.randomUUID()}`, this.referer = window.location.origin, this.formTarget = e.formTarget ?? "body", this.formProperties = {
      height: "1px"
    }, this.cdeLoadedPayload = null, this.ojsFlowsInitialization = null, this.checkoutFired = !1, this.connectionManager = new Wn(), this.eventHandler = new Zn(this), this.ojsVersion = "0.1.13", this.ojsReleaseVersion = "0.1.13", window.addEventListener("message", this.eventHandler.handleMessage.bind(this.eventHandler));
  }
  /**
   * Assign the instance to the window as a singleton
   * @param form - The OpenPayForm instance
   */
  static assignAsSingleton(e) {
    if (I.getInstance()) throw new Error("OpenPay instance already exists. Only one instance is allowed.");
    window.ojs = e;
  }
  /**
   * Get the singleton instance of OpenPayForm
   * @returns The OpenPayForm instance
   */
  static getInstance() {
    return window.ojs ?? null;
  }
  getConnectionManager() {
    return this.connectionManager;
  }
  setFormHeight(e) {
    this.formProperties.height = e, this.elements && Object.values(this.elements).forEach(o => {
      o.node.style.height = e;
    });
  }
  createElement(e, o = {}) {
    if (!this.config) throw new Error("OpenPay form not initialized");
    const r = Je.parse(e),
      s = document.createElement("iframe"),
      i = this.buildQueryString(o);
    s.name = `${r}-element`;
    const a = new URL(`/app/v1/${s.name}/`, this.config.baseUrl);
    a.search = i.toString(), s.src = a.href, this.config._frameUrl = a, s.style.border = "none", s.style.width = "100%";
    const c = {
      type: r,
      node: s,
      mount: l => {
        var u;
        return (u = document.querySelector(l)) == null ? void 0 : u.appendChild(s);
      }
    };
    return this.connectToElement(c), this.elements ? this.elements[r] = c : this.elements = {
      [r]: c
    }, c;
  }
  buildQueryString(e) {
    const o = new URLSearchParams();
    return o.append("referer", this.referer), o.append("formId", this.formId), e.styles && o.append("styles", Mt(e.styles)), o.append("secureToken", this.config.checkoutSecureToken), o;
  }
  connectToElement(e) {
    Gn(e.node).then(o => {
      this.connectionManager.addConnection(e.type, o), this.tryInitOjsFlows();
    }).catch(o => console.error("[FORM] Error connecting to CDE iframe", o));
  }
  getFormDiv() {
    return document.querySelector(this.formTarget) ?? document.body;
  }
  createOjsFlowContext() {
    const e = this.connectionManager.getAllConnections();
    if (!this.cdeLoadedPayload) throw new Error("Requested context while CDE not yet loaded");
    if (e.size === 0) throw new Error("No CDE connections found");
    return {
      baseUrl: new URL(this.config.baseUrl ?? Ue).origin,
      formDiv: this.getFormDiv(),
      elementsSessionId: this.cdeLoadedPayload.sessionId,
      checkoutPaymentMethods: this.cdeLoadedPayload.checkoutPaymentMethods,
      cdeConnections: e,
      customInitParams: this.config.customInitParams ?? {}
    };
  }
  createOjsFlowCallbacks() {
    const e = () => {};
    return {
      onCheckoutError: R("onCheckoutError", this.config.onCheckoutError ?? e),
      onCheckoutStarted: R("onCheckoutStarted", this.config.onCheckoutStarted ?? e),
      onCheckoutSuccess: R("onCheckoutSuccess", this.config.onCheckoutSuccess ?? e),
      onSetupPaymentMethodSuccess: R("onSetupPaymentMethodSuccess", this.config.onSetupPaymentMethodSuccess ?? e),
      onValidationError: R("onValidationError", this.config.onValidationError ?? e)
    };
  }
  submit() {
    const e = this.createOjsFlowContext();
    v.commonCC.run({
      context: e,
      checkoutPaymentMethod: ae(e.checkoutPaymentMethods, "credit_card"),
      nonCdeFormInputs: K(e.formDiv),
      flowCallbacks: this.createOjsFlowCallbacks(),
      customParams: void 0,
      // This flow requires no custom params
      initResult: void 0
      // This flow requires no initialization
    });
  }
  onPaymentRequestError(e) {
    console.error("[form] Error from payment request:", e), this.checkoutFired = !1, this.config.onCheckoutError && this.config.onCheckoutError(e);
  }
  destroy() {
    for (const e of Object.values(this.elements ?? {})) e.node.remove();
    window.removeEventListener("message", this.eventHandler.handleMessage.bind(this.eventHandler));
  }
};
I.ojsFlows = v;
let de = I;
export { m as FieldName, de as OpenPayForm };
