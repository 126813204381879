import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { IConversation } from '../../interfaces/conversations.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChatListService {
  private conversations: IConversation[] = [];

  public selectedConversationId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isChatsFullScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isChatsFullScreen$ = this.isChatsFullScreen.asObservable();
  private conversationsSubject = new BehaviorSubject<IConversation[]>(this.conversations);
  public conversations$ = this.conversationsSubject.asObservable();

  // Initialize or update conversations
  setConversations(conversations: IConversation[]): void {
    this.conversations = this.sortByLastMessage(conversations);
    this.conversationsSubject.next(this.conversations);
  }

  selectConversation(conversationId: string): void {
    this.conversations = this.conversations.map(conv => ({
      ...conv,
      isSelected: conv.conversation_id === conversationId
    }));
    this.conversationsSubject.next(this.conversations);
  }

  getConversationById(conversationId: string): IConversation | undefined {
    return this.conversations.find(conv => conv.conversation_id === conversationId);
  }

  addConversation(conversation: IConversation): void {
    this.conversations = [conversation, ...this.conversations];
    this.conversationsSubject.next(this.conversations);
  }

  toggleTypingIndicator(conversationId: string, isTyping: boolean): void {
    const conversation = this.conversations.find(conv => conv.conversation_id === conversationId);

    if (conversation) {
      conversation.isTyping = isTyping;
      this.conversationsSubject.next(this.conversations);
    }
  }

  updateLastMessage(conversationId: string, message: string): void {
    const timestamp = Date.now();
    const updatedConversations = this.conversations.map(conv =>
      conv.conversation_id === conversationId
        ? { ...conv, last_message: message, last_message_timestamp: timestamp }
        : conv
    );
    this.conversations = this.sortByLastMessage(updatedConversations);
  }

  private sortByLastMessage(conversations: IConversation[]): IConversation[] {
    return conversations.sort((a, b) => b.last_message_timestamp - a.last_message_timestamp);
  }
}
