<div class="content">
  <div class="auth-form">
    <div class="auth-form_svg">
      <stxt-svg-icon
        class="close_svg"
        (click)="closeModal()"
        (keydown.enter)="closeModal()"
        tabindex="0"
        [iconConfig]="closeSvg"
      ></stxt-svg-icon>
    </div>
    <div class="auth-form_content">
      <form [formGroup]="authForm">
        <div *ngIf="!isSignUp" class="auth-form_header">
          <img src="/assets/icons/stxt_favicon_d2c_auth.svg" alt="welcome-image" />
          <div class="auth-form_header__title">
            <p class="title-primary">LOG IN</p>
            <p class="title-secondary">TO STXT</p>
          </div>
          <p class="auth-form_subheading">Welcome back! Log in to continue.</p>
        </div>
        <div *ngIf="isSignUp" class="auth-form_header">
          <img src="/assets/icons/stxt_favicon_d2c_auth.svg" alt="welcome-image" />
          <div class="auth-form_header__title">
            <p class="title-primary">SIGN UP</p>
            <p class="title-secondary">TO CONTINUE</p>
          </div>
          <p class="auth-form_subheading">Create your STXT account to get started.</p>
        </div>
        <ng-container>
          <stxt-input
            class="auth-input"
            formControlName="email"
            [inputConfig]="emailInput"
          ></stxt-input>
          <stxt-input
            class="auth-input"
            [hintText]="passwordInput.hintText"
            formControlName="password"
            [inputConfig]="passwordInput"
          ></stxt-input>
          <stxt-button
            class="auth_btn auth_toggle__btn"
            ngbAutofocus
            (click)="executeRecaptcha()"
            [disabled]="!authForm.valid"
            [buttonConfig]="loginButton"
          >
            {{ isSignUp ? signUpButton.text : loginButton.text }}
          </stxt-button>
          <div class="divider">
            <p class="divider_text">or</p>
          </div>
          <stxt-button
            class="auth_btn__google"
            (click)="handleGoogleSignIn()"
            [buttonConfig]="googleButton"
          >
            <div class="auth-form_google flex items-center">
              <stxt-svg-icon [iconConfig]="googleSvg"></stxt-svg-icon>
              <p>{{ googleButton.text }}</p>
            </div>
          </stxt-button>
        </ng-container>
        <p class="auth-form_terms">
          By proceeding, you agree to our
          <button (click)="openTOS()" (keydown.enter)="openTOS()" tabindex="0">Terms of Use</button>
          and
          <button (click)="openPrivacyPolicy()" (keydown.enter)="openPrivacyPolicy()" tabindex="0">
            Privacy Policy
          </button>
          and confirm that you’re 18 years old. This site is protected by reCAPTCHA and the Google
          <button
            (click)="openGPrivacyPolicy()"
            (keydown.enter)="openGPrivacyPolicy()"
            tabindex="0"
          >
            Privacy Policy
          </button>
          and
          <button (click)="openGTOS()" (keydown.enter)="openGTOS()" tabindex="0">
            Terms of Service
          </button>
          apply.
        </p>

        <div class="auth-form_footer">
          <p>
            {{ !isSignUp ? 'Need an account?' : 'Already have an account?' }}
            <button (click)="toggleAuthType()" (keydown.enter)="toggleAuthType()" tabindex="0">
              {{ !isSignUp ? 'Sign up' : 'Log in' }}
            </button>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
