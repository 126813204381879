import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { first } from 'rxjs';

import { ConversationService } from '../../services/conversation.service';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

import { ISvgConfig } from '../../interfaces/svg.interfaces';

import { ESvgTypes } from '../../enums/svg.enums';
import { EPlayButtonUsage } from '../../enums/shared.enums';

@Component({
  selector: 'stxt-play-voice-button',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './play-voice-button.component.html',
  styleUrl: './play-voice-button.component.scss'
})
export class PlayVoiceButtonComponent {
  @Input() audio: string;
  @Input() audioId: string;
  @Input() usageCase: EPlayButtonUsage;
  @Input() creatorId?: string;
  @Input() selectedMessageAudio?: string;
  @Output() voicePlaying: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('audioPlayer') audioPlayerRef: ElementRef;
  audioWaveLength = Array(4);
  isVoicePlaying: boolean = false;
  isPlayButton: boolean = true;
  isGenerating: boolean = false;
  voiceWaveIcon: ISvgConfig = { name: 'voice-wave', fill: ESvgTypes.None };
  playIcon: ISvgConfig = { name: 'play_black', fill: ESvgTypes.None };
  protected readonly EPlayButtonUsage = EPlayButtonUsage;

  constructor(
    public conversationService: ConversationService,
    readonly cdr: ChangeDetectorRef
  ) {}

  toggleAudioPlayPause(event: Event): void {
    if (this.usageCase === EPlayButtonUsage.DiscoverCard) {
      event.stopPropagation();
    }
    if (this.isGenerating) return;
    !this.audio && this.usageCase === EPlayButtonUsage.ChatMessage
      ? this.generateAudio()
      : this.playPauseAudio();
  }

  generateAudio(): void {
    this.isGenerating = true;
    this.isPlayButton = false;
    this.conversationService
      .generateMessageAudio(this.creatorId, this.audioId)
      .pipe(first())
      .subscribe({
        next: res => {
          this.audio = res.voice_url;
          this.isGenerating = false;
          if (this.selectedMessageAudio !== this.audioId && this.selectedMessageAudio?.length)
            return;
          setTimeout(() => this.playPauseAudio(), 0);
          this.cdr.detectChanges();
        },
        error: err => {
          console.error(err);
        }
      });
  }

  playPauseAudio(): void {
    const audio = document.getElementById(`audioPlayer-${this.audioId}`) as HTMLAudioElement;
    if (!audio) return;
    if (audio.paused) {
      audio?.play();
      audio.currentTime = 0;
      this.voicePlaying.emit(true);
      this.isVoicePlaying = true;
      this.isPlayButton = false;
    } else {
      audio?.pause();
      audio.currentTime = 0;
      this.isVoicePlaying = false;
      this.isPlayButton = true;
      this.voicePlaying.emit(false);
    }
  }

  stopAudio(): void {
    this.isVoicePlaying = false;
    this.isPlayButton = true;
    const audio = document.getElementById(`audioPlayer-${this.audioId}`) as HTMLAudioElement;
    if (!audio) return;
    audio?.pause();
    audio.currentTime = 0;
    this.voicePlaying.emit(false);
    this.cdr.detectChanges();
  }
}
