import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BehaviorSubject, catchError, first, forkJoin, of, Subscription } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { SecurityComponent } from './security/security.component';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { ManageUserCredentialsComponent } from './manage-user-credentials/manage-user-credentials.component';
import { SupportComponent } from './support/support.component';
import { SubscriptionManagementComponent } from './subscription-management/subscription-management.component';
import { TransactionInfoComponent } from './subscription-management/transaction-info/transaction-info.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import {
  IBillingHistory,
  IBillingInfo,
  IBillingInfoData,
  IUserProfile,
  IUserSubscription
} from 'app/interfaces/profile.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { ESubscriptionState } from 'app/enums/purchases.enums';
import { EProfileSectionTitle, EProfileState } from 'app/enums/profile.enums';
import { ELocalStorageKey } from 'app/shared/enums/shared.enums';

@Component({
  selector: 'stxt-profile-flyout',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    SecurityComponent,
    PreferencesComponent,
    NotificationsComponent,
    PurchasesComponent,
    BillingInfoComponent,
    ManageUserCredentialsComponent,
    SupportComponent,
    SubscriptionManagementComponent,
    TransactionInfoComponent,
    VerifyEmailComponent
  ],
  templateUrl: './profile-flyout.component.html',
  styleUrl: './profile-flyout.component.scss'
})
export class ProfileFlyoutComponent implements OnInit, OnDestroy {
  @Output() closeDropdown: EventEmitter<string> = new EventEmitter<string>();
  public closeSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'close' };
  public credentialType: string;
  public isManageCredential: boolean = false;
  public isVerifyScreen: boolean = false;
  public isManageSubscription: boolean = false;
  public isTransactionInfo: boolean = false;
  public profileSettingsTitle$: BehaviorSubject<string> = this.profileService.profileActiveSection$;
  public profileUserData: IUserProfile;
  public billingInfo: IBillingInfo[];
  public transactions: IBillingHistory[];
  public userSubscriptions: IUserSubscription[] = [];
  public selectedSubscription: IUserSubscription;
  public selectedTransaction: IBillingHistory;
  public titleSubscription: Subscription;
  public titleValue: string;
  public subscriptionsLoaded: boolean = false;
  public analyticsTrackCompleted: boolean = false;
  protected readonly EProfileSectionTitle = EProfileSectionTitle;
  protected readonly EProfileState = EProfileState;
  userId = localStorage.getItem('userId');

  constructor(
    readonly profileService: ProfileService,
    readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.loadProfileData();
    this.titleSubscription = this.profileSettingsTitle$.subscribe(res => (this.titleValue = res));
  }

  loadProfileData(): void {
    forkJoin({
      profileUserData: this.profileService.getProfileData().pipe(
        first(),
        catchError(error => {
          console.error('Profile Data Error:', error);
          return of(error);
        })
      ),
      billingInfo: this.profileService.getBillingInfo().pipe(
        first(),
        catchError(error => {
          console.error('Billing Info Error:', error);
          return of(error);
        })
      ),
      userSubscriptions: this.profileService.getUserSubscriptions().pipe(
        first(),
        catchError(error => {
          console.error('User Subscriptions Error:', error);
          return of(error);
        })
      ),
      userTransactions: this.profileService.getAllUserTransactions().pipe(
        first(),
        catchError(error => {
          console.error('User Transactions Error:', error);
          return of(error);
        })
      )
    }).subscribe({
      next: ({ profileUserData, billingInfo, userSubscriptions, userTransactions }) => {
        this.profileUserData = profileUserData;
        this.billingInfo = billingInfo.data;
        this.transactions = userTransactions;
        if (userSubscriptions.length) this.setSubscriptionState(userSubscriptions);
        this.checkFirstTimeProfileOpen();
      },
      error: err => {
        //Handle errors if none of profile api is working
        console.error('Server error:', err);
      }
    });
  }

  updateSubscription(): void {
    this.profileService
      .getUserSubscriptions()
      .pipe(first())
      .subscribe(async (res: IUserSubscription[]) => {
        this.userSubscriptions = [];
        await this.setSubscriptionState(res);
        this.selectedSubscription = this.userSubscriptions.find(
          subscription => subscription.id === this.selectedSubscription.id
        );
      });
  }

  checkFirstTimeProfileOpen(): void {
    const profileOpened = localStorage.getItem(ELocalStorageKey.ProfileOpened);
    const isProfileComplete = !!(
      this.profileUserData?.email_verified &&
      this.profileUserData?.gender &&
      this.profileUserData?.interests &&
      this.billingInfo?.length
    );
    if (profileOpened || isProfileComplete) return;
    localStorage.setItem(ELocalStorageKey.ProfileOpened, 'true');
    this.analyticsService.onboardingProfile(EProfileState.PROFILE_STARTED, this.userId, null, null);
  }

  updateProfileData(): void {
    this.profileService
      .getProfileData()
      .pipe(first())
      .subscribe({
        next: (res: IUserProfile) => {
          this.profileUserData = res;
        },
        error: err => {
          console.error('Profile Data Error:', err);
        }
      });
  }

  async setSubscriptionState(userSubscriptions: IUserSubscription[]): Promise<void> {
    userSubscriptions.forEach(subscription => {
      let state: ESubscriptionState;
      const currentTime = new Date();
      const periodEndTime = new Date(subscription.current_period_ends_at);
      const isExpired = periodEndTime <= currentTime;
      if (subscription.auto_renew) {
        state = isExpired ? ESubscriptionState.Expired : ESubscriptionState.Active;
      } else {
        state = isExpired ? ESubscriptionState.Expired : ESubscriptionState.Soon;
      }

      const updatedSubscriptionItem = { ...subscription, state: state };
      this.userSubscriptions.push(updatedSubscriptionItem);
    });
    this.subscriptionsLoaded = true;
  }

  openManageCredentials(credentialType: string): void {
    this.isManageCredential = true;
    this.credentialType = credentialType;
    this.profileService.profileActiveSection$.next(`Manage ${credentialType}`);
  }

  openVerifyScreen(): void {
    this.isVerifyScreen = true;
    this.profileService.profileActiveSection$.next(EProfileSectionTitle.Verify_Email);
  }

  closeSettings(): void {
    if (this.isManageCredential || this.isTransactionInfo) {
      this.isManageCredential = false;
      this.isTransactionInfo = false;
      this.profileService.profileActiveSection$.next(EProfileSectionTitle.My_Profile);
    } else if (this.isVerifyScreen) {
      this.updateProfileData();
      this.isVerifyScreen = false;
    } else if (this.isManageSubscription) {
      this.checkManageCredentialsPart();
    } else {
      this.checkProfileStepCompleted();
    }
  }

  checkProfileStepCompleted(): void {
    const profileOpened = localStorage.getItem(ELocalStorageKey.ProfileOpened);
    if (!profileOpened) {
      this.closeDropdown.emit(EProfileState.PROFILE_CLOSE);
      return;
    }
    const isProfileComplete = !!(
      this.profileUserData?.email_verified &&
      this.profileUserData?.gender &&
      this.profileUserData?.interests &&
      this.billingInfo?.length &&
      profileOpened === 'true'
    );

    this.closeDropdown.emit(
      isProfileComplete ? EProfileState.PROFILE_COMPLETE : EProfileState.PROFILE_CLOSE
    );
    if (isProfileComplete && !this.analyticsTrackCompleted) {
      this.analyticsTrackCompleted = true;
      this.analyticsService.onboardingProfile(
        EProfileState.PROFILE_SETUP_COMPLETE,
        this.userId,
        null,
        null
      );
    }
  }

  checkManageCredentialsPart(): void {
    switch (this.titleValue) {
      case EProfileSectionTitle.View_Subscription_Info:
        this.isManageSubscription = false;
        this.profileService.profileActiveSection$.next(EProfileSectionTitle.My_Profile);
        break;
      case EProfileSectionTitle.View_Billing_History:
        this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Subscription_Info);
        break;
      case EProfileSectionTitle.View_Transaction_Info:
        this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Billing_History);
        break;
    }
  }

  fetchBillingInfo(): void {
    this.profileService
      .getBillingInfo()
      .pipe(first())
      .subscribe((res: IBillingInfoData) => {
        this.billingInfo = res.data;
      });
  }

  openSubscriptionManagement(subscription: IUserSubscription): void {
    this.isManageSubscription = true;
    this.selectedSubscription = subscription;
    this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Subscription_Info);
  }

  openTransactionInfo(purchase: IBillingHistory): void {
    this.isTransactionInfo = true;
    this.selectedTransaction = purchase;
    this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Transaction_Info);
  }

  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
  }
}
