export enum EChatStates {
  ONBOARDING_FREEBIE = 'onboardingFreebie',
  FREEFLOW = 'freeflow',
  WAITING_FOR_SUBSCRIPTION = 'waitingForSubscription'
}

export enum EChatFeatureAction {
  RELOAD = 'Reload',
  CLOSE = 'Close',
  SURPRISE_ME = 'Surprise Me',
  SELECTED_TAG = 'Selected Tag'
}

export enum EFeatureEvent {
  REQUEST_MEDIA_OPEN = 'requestMediaOpen',
  REQUEST_MEDIA_CLOSE = 'requestMediaClose',
  HandleSendTag = 'handleSendTag'
}
