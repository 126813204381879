import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { InputComponent } from 'app/shared/components/input/input.component';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { IInputCustomConfig } from 'app/shared/interfaces/input.interfaces';

import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';
import { EInputCustomType, EInputSupportMessage, EInputUsage } from 'app/shared/enums/input.enums';
import { EUserCredential } from 'app/enums/profile.enums';

import { PASSWORD_VALIDATOR_PATTERN } from '../../../shared/const/app.constants';

@Component({
  selector: 'stxt-manage-user-credentials',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonComponent, InputComponent],
  templateUrl: './manage-user-credentials.component.html',
  styleUrl: './manage-user-credentials.component.scss'
})
export class ManageUserCredentialsComponent implements OnInit, OnDestroy {
  @Input() userCredential: string;
  @Output() credentialsUpdated: EventEmitter<void> = new EventEmitter<void>();
  public credentialForm: FormGroup;
  public credentialFormValueChange$: Subscription;
  public confirmButton: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    text: 'Update',
    buttonSize: EButtonSizes.Default
  };
  public emailInput: IInputCustomConfig = {
    placeholder: 'Enter your email address',
    supportingText: {
      text: EInputSupportMessage.Email
    },
    type: EInputCustomType.Email,
    usageCase: EInputUsage.Page
  };
  public oldPasswordInput: IInputCustomConfig = {
    placeholder: 'Old password',
    supportingText: {
      text: EInputSupportMessage.Password
    },
    type: EInputCustomType.Password,
    usageCase: EInputUsage.Page
  };
  public passwordInput: IInputCustomConfig = {
    placeholder: 'New password',
    supportingText: {
      text: EInputSupportMessage.Password
    },
    type: EInputCustomType.Password,
    usageCase: EInputUsage.Page
  };
  public password2Input: IInputCustomConfig = {
    placeholder: 'Confirm new password',
    supportingText: {
      text: EInputSupportMessage.PasswordMatch
    },
    type: EInputCustomType.Password,
    usageCase: EInputUsage.Page,
    hintText: EInputSupportMessage.PasswordHint
  };
  protected readonly EUserCredential = EUserCredential;
  public passwordMismatch: boolean = false;
  public isPasswordError: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    switch (this.userCredential) {
      case EUserCredential.Email:
        this.initUpdateEmailForm();
        break;
      case EUserCredential.Password:
        this.initUpdatePasswordForm();
        break;
    }
  }

  initUpdateEmailForm(): void {
    this.credentialForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  initUpdatePasswordForm(): void {
    const passwordValidatorPattern: string = PASSWORD_VALIDATOR_PATTERN;

    this.credentialForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.pattern(passwordValidatorPattern)]],
      password: ['', [Validators.required, Validators.pattern(passwordValidatorPattern)]],
      password2: ['', [Validators.required]]
    });
    this.validateConfirmPassword();
  }

  validateConfirmPassword(): void {
    const passwordControl = this.credentialForm.get('password');
    const confirmPasswordControl = this.credentialForm.get('password2');

    this.credentialFormValueChange$ = this.credentialForm.valueChanges.subscribe(() => {
      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ mismatch: true });
      }
    });
  }

  updatePassword(): void {
    const oldPassword = this.credentialForm.get('oldPassword').value;
    const newPassword = this.credentialForm.get('password2').value;
    this.profileService.handleUpdatePassword({ oldPassword, newPassword }).then(() => {
      this.credentialsUpdated.emit();
    });
  }

  ngOnDestroy(): void {
    if (!this.credentialFormValueChange$) return;
    this.credentialFormValueChange$.unsubscribe();
  }
}
