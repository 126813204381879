import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';

import { MediaAudioComponent } from './media-type/media-audio/media-audio.component';
import { MediaVideoComponent } from './media-type/media-video/media-video.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { ICreatorContent } from '../../interfaces/creator.interfaces';
import { IButtonConfig } from '../../shared/interfaces/button.interfaces';
import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { EButtonSizes, EButtonTypes } from '../../shared/enums/button.enums';
import { ESvgTypes } from '../../shared/enums/svg.enums';
import { ECreatorVideoTypes } from '../../enums/creator.enums';

@Component({
  selector: 'stxt-creator-media-card',
  standalone: true,
  imports: [
    MediaAudioComponent,
    CommonModule,
    MediaVideoComponent,
    ButtonComponent,
    SvgIconComponent,
    NgOptimizedImage
  ],
  templateUrl: './creator-media-card.component.html',
  styleUrl: './creator-media-card.component.scss'
})
export class CreatorMediaCardComponent {
  @Input() media: ICreatorContent;
  @Input() userId: string;
  @Input() creatorId: string;
  chatButtonConfig: IButtonConfig = {
    fill: EButtonTypes.SecondaryDark,
    text: 'Chat Now',
    buttonSize: EButtonSizes.Small
  };
  chatIcon: ISvgConfig = {
    name: 'conversation',
    fill: ESvgTypes.None
  };
  protected readonly ECreatorVideoTypes = ECreatorVideoTypes;

  constructor(public router: Router) {}

  openChat(): void {
    this.router.navigateByUrl(`chat/${this.creatorId}`);
  }
}
