import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';

import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';
import { AuthService } from '../../auth/auth.service';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';
import { DiscoverCardBadgeComponent } from './discover-card-badge/discover-card-badge.component';
import { PlayVoiceButtonComponent } from '../../shared/components/play-voice-button/play-voice-button.component';

import { NumberCountPipe } from 'app/pipes/number-count.pipe';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import { IDiscoverItem } from '../../interfaces/discover.interfaces';
import { IAudioState } from '../../interfaces/creator.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { EPlayButtonUsage } from '../../shared/enums/shared.enums';

@Component({
  selector: 'stxt-discover-item',
  standalone: true,
  imports: [
    CommonModule,
    RecaptchaV3Module,
    SvgIconComponent,
    DiscoverCardBadgeComponent,
    PlayVoiceButtonComponent,
    NumberCountPipe,
    NgOptimizedImage
  ],
  templateUrl: './discover-item.component.html',
  styleUrl: './discover-item.component.scss',
  providers: [ReCaptchaV3Service]
})
export class DiscoverItemComponent implements OnInit, OnChanges {
  @Input() discoverItem: IDiscoverItem;
  @Input() userId: string;
  @Input() selectedPreviewAudio: string;
  @Output() updatePreviewVoiceState: EventEmitter<IAudioState> = new EventEmitter<IAudioState>();
  @ViewChild('previewVoice') previewVoice!: PlayVoiceButtonComponent;
  public creatorInfo: { creator_name: string; thumbnail_image: string };
  public chatSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'chat-outlined' };
  public chatFilledSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'chat-filled' };
  public clockSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'clock' };
  public clockFilledSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'clock-filled' };
  public verifySvg: ISvgConfig = { fill: ESvgTypes.None, name: 'verified' };
  public isChatLoading: boolean = false;
  public user = JSON.parse(localStorage.getItem('user'));
  public isMobile: boolean = false;
  protected readonly EPlayButtonUsage = EPlayButtonUsage;

  constructor(
    public router: Router,
    public authService: AuthService,
    public modal: NgbModal,
    readonly reCaptchaV3Service: ReCaptchaV3Service,
    readonly navigationIntentService: NavigationIntentService
  ) {}

  ngOnInit(): void {
    this.creatorInfo = {
      creator_name: this.discoverItem.name,
      thumbnail_image: this.discoverItem.image
    };
    this.checkScreenSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['selectedPreviewAudio'] &&
      this.selectedPreviewAudio !== this.discoverItem.id &&
      this.selectedPreviewAudio !== undefined &&
      this.previewVoice
    ) {
      this.previewVoice.stopAudio();
    }
  }

  async openChat(event: Event): Promise<void> {
    if (!this.discoverItem.ready) return;
    event.stopPropagation();
    if (this.isChatLoading) return;
    this.isChatLoading = true;

    try {
      await this.processChatNavigation();
    } finally {
      this.isChatLoading = false;
    }
  }

  private async processChatNavigation(): Promise<void> {
    if (!this.user) {
      try {
        await firstValueFrom(this.reCaptchaV3Service.execute('chat_now_action'));
      } catch (error) {
        console.error('reCAPTCHA execution error:', error);
        return;
      }
    }

    await this.authService.checkUserCredentials();
    localStorage.setItem('backUrl', '/');
    localStorage.setItem('creatorInfo', JSON.stringify(this.creatorInfo));
    if (this.authService.userExist$.value && this.authService.userLoaded$.value) {
      this.userId = localStorage.getItem('userId');
      await this.router.navigateByUrl(`chat/${this.discoverItem.id}`);
    } else {
      this.userId = localStorage.getItem('userId');
      this.navigationIntentService.setIntent(`/chat/${this.discoverItem.id}`);
      await this.router.navigateByUrl(`chat/${this.discoverItem.id}`);
    }
  }

  trackVoicePlaying(isPlaying: boolean): void {
    this.updatePreviewVoiceState.emit({ id: this.discoverItem.id, isPlaying: isPlaying });
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 640;
  }

  truncate(text: string, length: number): string {
    return this.isMobile && text.length > length ? text.substring(0, length) + '...' : text;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }
}
