<p class="preferences_title">Preferences</p>
<div class="preferences">
  <div class="preferences_items flex flex-column">
    <div [formGroup]="preferencesForm" class="preferences_item">
      <p class="preferences_item__heading">Name</p>
      <stxt-input formControlName="name" [inputConfig]="nameInput"></stxt-input>
    </div>
    <div class="preferences_item">
      <p class="preferences_item__heading">How do you identify?</p>
      <div class="preferences_item__options flex">
        <stxt-profile-option
          *ngFor="let option of identifyOptions"
          (click)="selectGender(option)"
          (keydown.enter)="selectGender(option)"
          [option]="option"
        ></stxt-profile-option>
      </div>
    </div>
    <div class="preferences_item">
      <p class="preferences_item__heading">Who are you interested in?</p>
      <div class="preferences_item__options flex">
        <stxt-profile-option
          *ngFor="let option of interestsOptions"
          (click)="toggleInterest(option)"
          (keydown.enter)="toggleInterest(option)"
          [option]="option"
        ></stxt-profile-option>
      </div>
    </div>
    <div class="preferences_item w-fit-content">
      <stxt-button
        [buttonConfig]="buttonConfig"
        (click)="saveProfilePreferences()"
        (keydown.enter)="saveProfilePreferences()"
        class="preferences_button"
      >
        {{ buttonConfig.text }}
      </stxt-button>
    </div>
  </div>
  <ng-container *ngIf="isToastMessage">
    <stxt-success-toast
      [toastHeading]="toast.toastHeading"
      [toastSubheading]="toast.toastSubheading"
      (closeToast)="isToastMessage = false"
      class="success-toast"
    ></stxt-success-toast>
  </ng-container>
</div>
