import { Injectable } from '@angular/core';
import { analytics } from '../../../main';

import { EProfileState } from 'app/enums/profile.enums';
import { ELocalStorageKey } from '../enums/shared.enums';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  trackPageLoaded(
    pageName: string,
    path: string,
    referrer: string,
    title: string,
    url: string,
    utmCampaign: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string
  ) {
    analytics.page({
      pageName: pageName,
      path: path,
      referrer: referrer,
      title: title,
      url: url,
      utmCampaign: utmCampaign,
      utmContent: utmContent,
      utmMedium: utmMedium,
      utmSource: utmSource,
      timestamp: new Date().toISOString()
    });
  }

  click(clickText: string, clickUrl: string, pageName: string, type: string, url: string): void {
    analytics.track('Click', {
      clickText: clickText,
      clickUrl: clickUrl,
      pageName: pageName,
      type: type,
      url: url,
      timestamp: new Date().toISOString()
    });
  }

  scrollDepth(depth: number, pageName: string, url: string): void {
    analytics.track('Scroll Depth', {
      depth: depth,
      pageName: pageName,
      url: url,
      timestamp: new Date().toISOString()
    });
  }

  chatInitiated(
    userId: string,
    creatorName: string,
    chatState: string,
    chatId: string,
    creatorId: string
  ) {
    analytics.track('Chat Initiated', {
      userId: userId,
      creatorName: creatorName,
      chatState: chatState,
      chatId: chatId,
      creatorId: creatorId,
      timestamp: new Date().toISOString()
    });
  }

  onboardingStarted(userId: string, creatorName: string, chatState: string, creatorId: string) {
    analytics.track('Onboarding Started', {
      userId: userId,
      creatorName: creatorName,
      chatState: chatState,
      creatorId: creatorId,
      timestamp: new Date().toISOString()
    });
  }

  onboardingProfile(stepName: string, userId: string, creatorName: string, creatorId: string) {
    if (localStorage.getItem(ELocalStorageKey.ProfileCompleted) === 'true') return;
    analytics.track(EProfileState.PROFILE_STEP_COMPLETED, {
      stepName: stepName,
      userId: userId,
      creatorName: creatorName,
      creatorId: creatorId,
      timestamp: new Date().toISOString()
    });
  }

  onboardingStepCompleted(
    userId: string,
    creatorName: string,
    stepName: string,
    creatorId: string
  ) {
    analytics.track(EProfileState.PROFILE_STEP_COMPLETED, {
      userId: userId,
      creatorName: creatorName,
      stepName: stepName,
      creatorId: creatorId,
      timestamp: new Date().toISOString()
    });
  }

  voiceNotePlayed(userId: string, creatorName: string, chatState: string, creatorId: string) {
    analytics.track('Voice Note Played', {
      userId: userId,
      creatorName: creatorName,
      chatState: chatState,
      creatorId: creatorId,
      timestamp: new Date().toISOString()
    });
  }

  subscribeMessageSent(userId: string, stepName: string, creatorId: string) {
    analytics.track('Subscribe Message Sent', {
      userId: userId,
      stepName: stepName,
      creatorId: creatorId,
      timestamp: new Date().toISOString()
    });
  }
}
