import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EUserCredential } from 'app/enums/profile.enums';

import { SUPPORT_LINK } from '../../../shared/const/app.constants';

@Component({
  selector: 'stxt-security',
  standalone: true,
  imports: [CommonModule, ButtonComponent, SvgIconComponent],
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss'
})
export class SecurityComponent {
  @Input() userEmail: string;
  @Input() emailVerified: boolean;
  @Input() externalProvider: boolean;
  @Output() openManageScreen: EventEmitter<string> = new EventEmitter<string>();
  @Output() openVerifyScreen: EventEmitter<string> = new EventEmitter<string>();

  public connectBtnConfig: IButtonConfig = {
    text: 'Connect',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Small
  };
  public supportLink: string = SUPPORT_LINK;
  public arrowSvgConfig: ISvgConfig = { name: 'arrow_right', fill: ESvgTypes.None };
  public verifiedSvgConfig: ISvgConfig = { name: 'done', fill: ESvgTypes.None };
  public notVerifiedSvgConfig: ISvgConfig = { name: 'info', fill: ESvgTypes.None };
  protected readonly EUserCredential = EUserCredential;

  openVerifyEmail(): void {
    this.openVerifyScreen.emit(this.userEmail);
  }

  navigateToManageEmail(): void {
    this.emailVerified ? this.openManageScreen.emit(EUserCredential.Email) : this.openVerifyEmail();
  }
}
