<button
  [ngClass]="{
    'voice-button_active': isVoicePlaying,
    'voice-button_discover': usageCase === EPlayButtonUsage.DiscoverCard,
    'voice-button_chat': usageCase === EPlayButtonUsage.ChatMessage,
    'voice-button_generating': isGenerating
  }"
  class="voice-button btn-nostyle"
  (click)="toggleAudioPlayPause($event)"
  (keydown.enter)="toggleAudioPlayPause($event)"
>
  <audio
    *ngIf="audio"
    #audioPlayer
    id="audioPlayer-{{ audioId }}"
    class="opacity-0"
    (ended)="stopAudio()"
  >
    <source [src]="audio" class="opacity-0" />
  </audio>
  <div class="voice-button_controls">
    <stxt-svg-icon
      *ngIf="isPlayButton && !isGenerating"
      class="voice-wave_svg"
      [iconConfig]="usageCase === EPlayButtonUsage.DiscoverCard ? voiceWaveIcon : playIcon"
    ></stxt-svg-icon>

    <span *ngIf="isGenerating" class="loader"></span>

    <div *ngIf="isVoicePlaying" class="wave">
      <span
        *ngFor="let index of audioWaveLength"
        [ngClass]="{ animate: isVoicePlaying }"
        class="stroke"
      ></span>
    </div>
  </div>
</button>
