import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

import { ISvgConfig } from '../../interfaces/svg.interfaces';

import { ESvgTypes } from '../../enums/svg.enums';

import { fadeAnimation, fadeAnimationSequence } from '../../animations/fade.animations';

@Component({
  selector: 'stxt-success-toast',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './success-toast.component.html',
  styleUrl: './success-toast.component.scss',
  animations: [fadeAnimationSequence, fadeAnimation]
})
export class SuccessToastComponent implements OnInit {
  @Input() toastHeading: string;
  @Input() toastSubheading: string;
  @Output() closeToast: EventEmitter<void> = new EventEmitter<void>();

  checkVerifyIcon: ISvgConfig = { name: 'verify', fill: ESvgTypes.None };
  closeIcon: ISvgConfig = { name: 'close', fill: ESvgTypes.None };

  ngOnInit(): void {
    setTimeout(() => {
      this.closeToast.emit();
    }, 5000);
  }
}
