export enum EAuthFormType {
  Auth = 'Auth',
  Lander = 'Lander'
}

export enum EResendCodeUsage {
  Profile = 'profile',
  Chat = 'chat'
}

export enum ELocalStorageKey {
  VerifySent = 'verifySent',
  CognitoAccessToken = 'cognitoAccessToken',
  CountdownVerificationStartTime = 'countdownVerificationStartTime',
  RedirectUrl = 'redirectUrl',
  ProfileOpened = 'profileOpened',
  ProfileCompleted = 'profileCompleted',
  User = 'user',
  UserId = 'userId',
  AccessToken = 'accessToken',
  CreatorInfo = 'creatorInfo',
  BackUrl = 'backUrl'
}

export enum ECarouselNavigation {
  Previous = 'previous',
  Next = 'next'
}

export enum EPlayButtonUsage {
  DiscoverCard = 'discoverCard',
  ChatMessage = 'chatMessage'
}
