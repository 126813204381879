import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { first } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { PaymentFormComponent } from '../../payment/payment-form/payment-form.component';

import { IBillingInfo } from 'app/interfaces/profile.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EPaymentType } from 'app/enums/payments.enums';

@Component({
  selector: 'stxt-billing-info',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, PaymentFormComponent],
  templateUrl: './billing-info.component.html',
  styleUrl: './billing-info.component.scss'
})
export class BillingInfoComponent {
  @Input() billingInfo: IBillingInfo[];
  @Output() billingMethodAdded: EventEmitter<string> = new EventEmitter<string>();

  public readonly EPaymentType = EPaymentType;
  public infoSvgConfig: ISvgConfig = { name: 'info', fill: ESvgTypes.None };
  public removeBillingMethodSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'close' };

  constructor(private profileService: ProfileService) {}

  handleUpdateBilling(): void {
    this.billingMethodAdded.emit('success');
  }

  removeBillingMethod(id: string): void {
    this.profileService
      .removeBillingInfo(id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.billingInfo = this.billingInfo.filter((method: IBillingInfo) => method.id !== id);
        },
        error: err => {
          if (err.status == 200) {
            //TODO: Handle error if any
            this.billingInfo = this.billingInfo.filter((method: IBillingInfo) => method.id !== id);
          }
        }
      });
  }
}
