<div class="purchase_item flex space-between">
  <p>
    <span
      [ngClass]="{
        paid: purchase.state === EPurchaseState.Paid,
        canceled: purchase.state === EPurchaseState.Canceled
      }"
      class="purchase_item__status"
    ></span>
    {{ purchase.state | titlecase }}
    <span *ngIf="purchase.state === EPurchaseState.Canceled">payment</span>
    {{ purchase.total | currency }}
    total on {{ purchase.closed_at | date: 'MM/dd/yyyy' }}
  </p>
  <stxt-svg-icon
    [iconConfig]="rightNavSvg"
    (click)="openTransactionInfo(purchase)"
    (keydown.enter)="openTransactionInfo(purchase)"
    class="purchase_item__svg"
    tabindex="-1"
  ></stxt-svg-icon>
</div>
