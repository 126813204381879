<div
  class="flyout-profile_overlay"
  (click)="checkProfileStepCompleted()"
  (keydown.enter)="checkProfileStepCompleted()"
  [tabindex]="0"
>
  <div
    (click)="$event.stopPropagation()"
    (keydown.enter)="$event.stopPropagation()"
    class="flyout-profile"
    [tabindex]="0"
  >
    <section class="flyout-profile_nav items-center">
      <stxt-svg-icon
        [iconConfig]="closeSvg"
        (click)="closeSettings()"
        (keydown.enter)="closeSettings()"
        tabindex="-1"
      ></stxt-svg-icon>
      <p class="flyout-profile_nav__title">{{ profileSettingsTitle$ | async }}</p>
    </section>
    <section
      [ngClass]="{
        'padding-subscription':
          (profileSettingsTitle$ | async) === EProfileSectionTitle.View_Subscription_Info,
        'padding-verify': isVerifyScreen,
        'padding-default':
          (profileSettingsTitle$ | async) !== EProfileSectionTitle.View_Subscription_Info &&
          !isVerifyScreen
      }"
      class="flyout-profile_body relative flex w-full"
    >
      <ng-container
        *ngIf="
          !isManageCredential && !isManageSubscription && !isTransactionInfo && !isVerifyScreen
        "
      >
        <stxt-preferences
          [interestsData]="profileUserData?.interests"
          [genderData]="profileUserData?.gender"
          [name]="profileUserData?.name"
        ></stxt-preferences>
        <stxt-security
          (openManageScreen)="openManageCredentials($event)"
          (openVerifyScreen)="openVerifyScreen()"
          [userEmail]="profileUserData?.email"
          [emailVerified]="profileUserData?.email_verified"
          [externalProvider]="profileUserData?.external_provider"
        ></stxt-security>
        <stxt-billing-info
          *ngIf="profileUserData?.email_verified"
          [billingInfo]="billingInfo"
          (billingMethodAdded)="fetchBillingInfo()"
        ></stxt-billing-info>
        <stxt-purchases
          *ngIf="subscriptionsLoaded"
          [subscriptionsList]="userSubscriptions"
          [oneTimePurchase]="transactions"
          (closeProfileEvent)="closeDropdown.emit(EProfileState.PROFILE_CLOSE)"
          (openTransactionInfoEvent)="openTransactionInfo($event)"
          (openSubscriptionManagementEvent)="openSubscriptionManagement($event)"
        ></stxt-purchases>
        <!--    TODO -- Notifications in progress-->
        <!-- <stxt-notifications></stxt-notifications> -->
        <stxt-support
          class="w-full"
          (logoutEvent)="closeDropdown.emit(EProfileState.PROFILE_LOGOUT)"
        ></stxt-support>
      </ng-container>
      <ng-container *ngIf="isManageCredential">
        <stxt-manage-user-credentials
          [userCredential]="credentialType"
          (credentialsUpdated)="closeSettings()"
          class="w-full h-full"
        ></stxt-manage-user-credentials>
      </ng-container>
      <ng-container *ngIf="isManageSubscription">
        <stxt-subscription-management
          [selectedSubscription]="selectedSubscription"
          (autoRenewChange)="updateSubscription()"
          class="w-full h-full"
        ></stxt-subscription-management>
      </ng-container>
      <ng-container *ngIf="isTransactionInfo">
        <stxt-transaction-info [transaction]="selectedTransaction"></stxt-transaction-info>
      </ng-container>
      <ng-container *ngIf="isVerifyScreen">
        <stxt-verify-email
          [email]="profileUserData?.email"
          (emailVerified)="closeSettings()"
          class="w-full h-full verify-email_section"
        ></stxt-verify-email>
      </ng-container>
    </section>
  </div>
</div>
