import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateFn
} from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';
import { AuthService } from './auth.service';

import { AuthFormComponent } from 'app/shared/components/auth-form/auth-form.component';

const checkAgeVerification = (
  navigationIntentService: NavigationIntentService,
  intendedUrl: string
): boolean => {
  const isAgeVerified = localStorage.getItem('ageVerified') === 'true';
  if (!isAgeVerified) {
    navigationIntentService.setIntent(intendedUrl);
  }
  return isAgeVerified;
};

const openAuthModal = (modalService: NgbModal, isClosable = true) => {
  const modalRef = modalService.open(AuthFormComponent, { backdrop: 'static', keyboard: false });
  modalRef.componentInstance.isSignUp = true;
  if (!isClosable) {
    modalRef.componentInstance.closeAndNavigate = true;
  }
  return modalRef;
};

const handleNonAuthenticatedUser = (
  state: string,
  navigationIntentService: NavigationIntentService,
  modalService: NgbModal,
  authService: AuthService,
  isChatRoute: boolean
): Observable<boolean> => {
  navigationIntentService.setIntent(state);

  if (isChatRoute) {
    setTimeout(() => {
      openAuthModal(modalService, false);
    });
    return from(Promise.resolve(true));
  }

  const modalRef = openAuthModal(modalService);
  return modalRef.closed.pipe(
    switchMap(() => from(authService.currentSession())),
    switchMap(() => authService.$isUser.pipe(take(1))),
    switchMap(isUserAfterModal => {
      if (isUserAfterModal) {
        navigationIntentService.navigateToIntentOrDefault();
      }
      return from(Promise.resolve(false));
    })
  );
};

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService = inject(AuthService);
  const modalService = inject(NgbModal);
  const navigationIntentService = inject(NavigationIntentService);

  if (!checkAgeVerification(navigationIntentService, state.url)) {
    return false;
  }

  const isChatRoute = state.url.startsWith('/chat/');

  return from(authService.currentSession()).pipe(
    switchMap(() => authService.$isUser.pipe(take(1))),
    switchMap(isUser =>
      isUser
        ? from(Promise.resolve(true))
        : handleNonAuthenticatedUser(
            state.url,
            navigationIntentService,
            modalService,
            authService,
            isChatRoute
          )
    )
  );
};
