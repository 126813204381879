<ng-container *ngIf="!isLander">
  <div class="home">
    <div class="home_banner">
      <div class="home_banner__carousel">
        <ngb-carousel
          class="carousel_fade"
          [showNavigationArrows]="showIndicators"
          [showNavigationIndicators]="showIndicators"
        >
          <ng-container *ngFor="let discoverItem of discoverData">
            <ng-template *ngIf="discoverItem?.featured" ngbSlide>
              <div class="picsum-img-wrapper">
                <img [src]="discoverItem.banner_image" alt="slide" />
              </div>
              <div class="carousel-caption flex">
                <div class="home_banner__info">
                  <div>
                    <p class="home_banner__name">Chat with {{ discoverItem.name }}</p>
                    <p class="home_banner__description">{{ discoverItem.description }}</p>
                  </div>
                  <stxt-button
                    (click)="navigateToChat(chatNavButton.text, discoverItem)"
                    (keydown.enter)="navigateToChat(chatNavButton.text, discoverItem)"
                    [buttonConfig]="chatNavButton"
                    class="home_banner__button"
                  >
                    {{ chatNavButton.text }}
                  </stxt-button>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ngb-carousel>
      </div>
    </div>
    <div *ngIf="discoverData" class="home_main">
      <div class="home_main__heading">
        <p>
          See who’s interested in
          <span>chatting with you</span>
        </p>
      </div>
      <div class="home_main__items">
        <ng-container *ngFor="let discoverItem of discoverData">
          <stxt-discover-item
            [discoverItem]="discoverItem"
            [userId]="userId"
            [selectedPreviewAudio]="selectedPreviewAudio"
            (updatePreviewVoiceState)="trackSelectedVoice($event)"
            class="home_main__item"
          ></stxt-discover-item>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
