<div class="chat_feed">
  <div
    [@animatePosition]
    *ngFor="let conversation of conversationsList; trackBy: trackById"
    class="conversation-item"
  >
    <stxt-chat-item
      [conversation]="conversation"
      (click)="selectConversation(conversation.conversation_id)"
      (keydown.enter)="selectConversation(conversation.conversation_id)"
    ></stxt-chat-item>
  </div>
</div>
