import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import {
  NgbCarousel,
  NgbCarouselConfig,
  NgbCarouselModule,
  NgbSlideEvent
} from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { SvgIconComponent } from '../../../../shared/components/svg-icon/svg-icon.component';

import { IMediaData } from 'app/interfaces/conversations.interfaces';
import { IButtonConfig } from '../../../../shared/interfaces/button.interfaces';
import { ISvgConfig } from '../../../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../../../shared/enums/svg.enums';
import { EButtonTypes } from '../../../../shared/enums/button.enums';

@Component({
  selector: 'stxt-message-media',
  standalone: true,
  imports: [NgbCarouselModule, CommonModule, ButtonComponent, SvgIconComponent, NgOptimizedImage],
  templateUrl: './message-media.component.html',
  styleUrl: './message-media.component.scss',
  providers: [NgbCarouselConfig]
})
export class MessageMediaComponent implements OnInit, OnChanges {
  @Input() media: IMediaData;
  @Output() imagesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isPreviewMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openPayment: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('carousel') set carouselRef(carousel: NgbCarousel) {
    if (carousel) {
      this._carousel = carousel;
      this.onCarouselReady();
    }
  }

  private _carousel: NgbCarousel;
  mediaFullScreen: boolean = false;
  mediaOwned: boolean;
  isMediaLoaded: boolean = false;
  unlockBtn: IButtonConfig = { text: 'Unlock', fill: EButtonTypes.SecondaryFilled };
  viewBtn: IButtonConfig = { text: 'View', fill: EButtonTypes.SecondaryDark };
  closeSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'close' };
  lockSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'lock' };
  leftNavigation: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'arrow_left' };
  rightNavigation: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'arrow_right' };
  imageSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'photo' };
  unlockMediaSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'lock' };
  currentSlide: string;

  constructor(
    config: NgbCarouselConfig,
    private cdr: ChangeDetectorRef
  ) {
    config.wrap = false;
    config.interval = 0;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.animation = false;
  }

  ngOnInit(): void {
    this.checkMediasQuantity();
    this.mediaOwned = this.isMediaOwned();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['media']) {
      this.mediaOwned = this.isMediaOwned();
    }
  }

  isMediaOwned(): boolean {
    return this.media.files.every(file => file && file.owned);
  }

  checkMediasQuantity(): void {
    if (this.media.photos === 1) return;
    this.viewBtn.text = 'View all';
    this.unlockBtn.text = 'Unlock all';
  }

  onImageLoad(): void {
    this.isMediaLoaded = true;
    this.imagesLoaded.emit(true);
  }

  togglePreview(value: boolean): void {
    this.isPreviewMode.emit(value);
    this.mediaFullScreen = value;
    if (value) {
      this.onCarouselReady();
    }
  }

  onCarouselReady(): void {
    if (this._carousel && this.mediaFullScreen) {
      this._carousel.select(this.currentSlide);
      this.cdr.detectChanges();
    }
  }

  onSlide($event: NgbSlideEvent): void {
    this.currentSlide = $event.current;
  }

  unlockImage(): void {
    this.mediaOwned || this.media.price === 0 ? this.togglePreview(true) : this.openPayment.emit();
  }
}
