<div class="payment_overlay">
  <div class="payment absolute flex">
    <div class="payment_head flex items-center">
      <div></div>
      <p class="payment_heading">Check out</p>
      <stxt-svg-icon
        [iconConfig]="closeSvg"
        (click)="closePayment({ status: EPaymentStatus.Close, paymentType: paymentType })"
        (keydown.enter)="closePayment({ status: EPaymentStatus.Close, paymentType: paymentType })"
        class="checkout_icon"
      ></stxt-svg-icon>
    </div>
    <div class="payment_main">
      <ng-container
        *ngTemplateOutlet="activeWindow === 'checkout' ? checkout : paymentMethodList"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #checkout>
  <stxt-checkout
    *ngIf="dataLoaded"
    [billingData]="billingData?.data"
    [creator]="creator"
    [creatorId]="creatorId"
    [firstCheckOut]="firstCheckOut"
    [paymentType]="paymentType"
    [purchaseData]="purchaseData"
    [subscriptionData]="subscriptionData"
    [wsConnectionId]="wsConnectionId"
    (openPaymentList)="activeWindow = 'paymentMethodList'"
    (closeCheckoutEvent)="closePayment($event)"
    class="checkout-component h-full"
  ></stxt-checkout>
</ng-template>
<ng-template #paymentMethodList>
  <ng-container *ngIf="activeWindow === 'newPaymentMethod'">
    <stxt-add-payment-method
      [creatorId]="creatorId"
      [firstCheckOut]="firstCheckOut"
      [paymentType]="paymentType"
      [wsConnectionId]="wsConnectionId"
      (goBackEvent)="activeWindow = 'checkout'"
      class="checkout-component h-full"
    ></stxt-add-payment-method>
  </ng-container>
  <ng-container *ngIf="activeWindow === 'paymentMethodList'">
    <stxt-payment-method-list
      [paymentType]="paymentType"
      (goBackEvent)="activeWindow = 'checkout'"
      (newPaymentEvent)="activeWindow = 'newPaymentMethod'"
      class="h-full"
    ></stxt-payment-method-list>
  </ng-container>
</ng-template>
