export enum EUserCredential {
  Password = 'password',
  Email = 'email address'
}

export enum EProfileSectionTitle {
  My_Profile = 'My profile',
  View_Subscription_Info = 'View subscription info',
  View_Billing_History = 'View billing history',
  View_Transaction_Info = 'View transaction info',
  Verify_Email = 'Verify email'
}

export enum EProfileState {
  PROFILE_STARTED = 'onboardingProfileStarted',
  PROFILE_IDENTIFY = 'onboardingProfileIdentify',
  PROFILE_INTERESTS = 'onboardingProfileInterests',
  PROFILE_EMAIL_VERIFICATION = 'onboardingProfileVerifiedEmail',
  PROFILE_BILLING_INFO = 'onboardingProfileBillingInfo',
  PROFILE_SETUP_COMPLETE = 'onboardingProfileSetupComplete',
  //Changed cammelcase due to request from analytics team
  PROFILE_STEP_COMPLETED = 'Onboarding Step Completed',
  PROFILE_ASK_NAME = 'onboardingStepCompletedAskName',
  PROFILE_CLOSE = 'close',
  PROFILE_COMPLETE = 'profile-complete',
  PROFILE_LOGOUT = 'logout'
}
