/* eslint-disable */
import { Amplify } from 'aws-amplify';
import { bootstrapApplication } from '@angular/platform-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from './environments/environment';
import 'aws-amplify/auth/enable-oauth-listener';
import * as Sentry from '@sentry/angular';
import { ELocalStorageKey } from 'app/shared/enums/shared.enums';
import { EUserAuthType } from 'app/auth/auth.enums';
import { EProfileState } from 'app/enums/profile.enums';

export const analytics = AnalyticsBrowser.load({
  writeKey: environment.analyticsKey
});

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          responseType: 'code',
          redirectSignIn: JSON.parse(environment.redirectSignIn),
          redirectSignOut: JSON.parse(environment.redirectSignOut),
          domain: environment.domain,
          scopes: ['email', 'openid']
        }
      },
      userPoolClientId: environment.userPoolClientId,
      userPoolId: environment.userPoolId
    }
  }
});

Hub.listen('auth', async ({ payload }) => {
  switch (payload.event) {
    case 'signInWithRedirect':
      fetchAuthSession().then(res => {
        const stxt_sub = res.tokens.idToken.payload['stxt_sub'] as string;
        const email = res.tokens.idToken.payload['email'] as string;
        const userId = localStorage.getItem(ELocalStorageKey.UserId);

        localStorage.setItem(
          ELocalStorageKey.User,
          JSON.stringify({
            username: email,
            type: EUserAuthType.Google_Sign_In
          })
        );
        analytics.track(EProfileState.PROFILE_STEP_COMPLETED, {
          stepName: EProfileState.PROFILE_EMAIL_VERIFICATION,
          userId: userId,
          timestamp: new Date().toISOString()
        });

        analytics.identify(stxt_sub, {
          email: email
        });

        const redirectUrl = localStorage.getItem(ELocalStorageKey.RedirectUrl);
        if (redirectUrl) {
          window.location.href = redirectUrl;
          localStorage.removeItem(ELocalStorageKey.RedirectUrl);
        } else {
          window.location.href = '/';
        }
      });
      break;
    case 'signInWithRedirect_failure':
      // handle sign in failure
      localStorage.removeItem(ELocalStorageKey.RedirectUrl);
      console.log('failed google auth');
      break;
    case 'tokenRefresh':
      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens?.idToken?.toString();
        const accessToken = session.tokens?.accessToken?.toString();

        if (idToken) {
          localStorage.setItem('accessToken', idToken);
        }
        if (accessToken) {
          localStorage.setItem('cognitoAccessToken', accessToken);
        }
        console.log('Token refreshed successfully');
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
      break;
    case 'customOAuthState':
      const customState = payload.data; // this will be customState provided on signInWithRedirect function
      console.log(customState);
      break;
  }
});
Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.8,
  environment: environment.serviceEnvironment,
  tracePropagationTargets: [
    'localhost',
    'https://staging.stxt.ai',
    'https://develop.stxt.ai',
    'https://app.stxt.ai'
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
