import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from 'app/shared/services/analytics.service';
import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';

import { ButtonComponent } from '../../shared/components/button/button.component';

import { IButtonConfig } from '../../shared/interfaces/button.interfaces';

import { EButtonActionTypes, EButtonSizes, EButtonTypes } from '../../shared/enums/button.enums';

@Component({
  selector: 'stxt-age-verification',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './age-verification.component.html',
  styleUrl: './age-verification.component.scss'
})
export class AgeVerificationComponent {
  confirmAgeBtn: IButtonConfig = {
    text: 'I’m 18+ • Enter Now',
    fill: EButtonTypes.Colored,
    buttonSize: EButtonSizes.Large
  };
  closeBtn: IButtonConfig = {
    text: 'I’m under 18',
    fill: EButtonTypes.Dark,
    buttonSize: EButtonSizes.Large
  };

  constructor(
    public activeModal: NgbActiveModal,
    public analyticsService: AnalyticsService,
    private readonly navigationIntentService: NavigationIntentService
  ) {}

  closeModal(): void {
    window.open('https://www.google.com/', '_self');
    this.analyticsService.click(
      this.closeBtn.text,
      'https://www.google.com/',
      'Home',
      EButtonActionTypes.AgeVerification,
      'https://www.google.com/'
    );
  }

  confirmAge(): void {
    this.activeModal.close();
    localStorage.setItem('ageVerified', String(true));
    this.navigationIntentService.navigateToIntentOrDefault();
    this.analyticsService.click(
      this.closeBtn.text,
      '',
      'Home',
      EButtonActionTypes.AgeVerification,
      ''
    );
  }

  openTOS(): void {
    window.open('https://stxt.notion.site/Terms-of-Use-62cc644f192f4d7b9dc17d7cb5c9f891', '_blank');
  }
}
