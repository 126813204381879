<div class="flex message-box">
  <p *ngIf="!messageItem.from_stxt" class="message_time">
    {{ messageItem.timestamp | date: 'shortTime' }}
  </p>
  <ng-container *ngIf="messageItem?.category !== 'medias'">
    <div
      (click)="clicked.emit()"
      (keydown.enter)="clicked.emit()"
      [ngClass]="messageItem.from_stxt ? 'ai_message' : 'user_message'"
      class="message flex items-center"
      tabindex="0"
    >
      <div class="flex space-between items-center">
        <p
          *ngIf="messageItem.category !== 'voice'"
          [innerHTML]="messageItem?.payload?.message"
          class="message_text"
        ></p>
        <stxt-play-voice-button
          #playMessage
          *ngIf="messageItem.from_stxt && voiceSupported"
          [audio]="messageItem?.payload?.voice_url"
          [audioId]="messageItem.timestamp"
          [selectedMessageAudio]="selectedMessageAudio"
          [creatorId]="creatorId"
          [usageCase]="EPlayButtonUsage.ChatMessage"
          (voicePlaying)="trackMessagePlaying($event)"
          class="message_play"
        ></stxt-play-voice-button>
      </div>
      <p *ngIf="messageItem.category === 'voice'" class="message_voice">
        <span
          [innerHTML]="truncateVoiceText(messageItem?.payload?.message, isMessageExpanded)"
        ></span>
        <a
          *ngIf="messageItem?.payload?.message.length > 30"
          (click)="expandVoiceText()"
          (keydown.enter)="expandVoiceText()"
          tabindex="0"
        >
          {{ isMessageExpanded ? 'Show less' : 'Show more' }}
        </a>
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="messageItem?.category === 'medias'">
    <stxt-message-media
      (imagesLoaded)="mediaLoaded = true"
      (isPreviewMode)="isPreviewMode.emit($event)"
      (openPayment)="triggerPayment.emit()"
      [media]="messageItem.payload"
    ></stxt-message-media>
  </ng-container>
  <p *ngIf="messageItem.from_stxt" class="message_time">
    {{ messageItem.timestamp | date: 'shortTime' }}
  </p>
</div>
