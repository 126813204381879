<div class="header flex">
  <div class="header_logo">
    <img
      (click)="router.navigateByUrl('')"
      (keydown.enter)="router.navigateByUrl('')"
      src="assets/logo/STXT.svg"
      class="header_logo__img"
      alt="stxt"
      tabindex="-1"
    />
  </div>
  <ng-container *ngIf="$isUser | async">
    <div class="header_nav flex">
      <div class="flex">
        <a
          routerLinkActive="active-link"
          [routerLink]="['']"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="trackCTAEvent('Discover', '')"
          class="header_nav__item flex"
        >
          <stxt-svg-icon class="header_icon" [iconConfig]="homeSvg"></stxt-svg-icon>
          <span class="header_nav__title">Discover</span>
        </a>
        <button
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="openChat()"
          class="header_nav__item flex btn-nostyle"
        >
          <stxt-svg-icon class="header_icon" [iconConfig]="chatSvg"></stxt-svg-icon>
          <span class="header_nav__title">Chat</span>
        </button>
      </div>
      <a
        (click)="openProfile()"
        (keydown.enter)="openProfile()"
        class="header_nav__item flex profile_nav relative"
        tabindex="0"
      >
        <stxt-svg-icon class="header_icon" [iconConfig]="profileSvg"></stxt-svg-icon>
        <span class="header_nav__title">Profile</span>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="($isUser | async) === false">
    <div class="flex">
      <a
        class="header_nav__auth-item flex login_nav"
        (click)="openAuth('login', 'Log in')"
        (keydown.enter)="openAuth('login', 'Log in')"
        tabindex="0"
      >
        <span class="auth-button margin-none">Log in</span>
      </a>
      <a
        class="header_nav__auth-item flex sign-up_nav flex items-center"
        (click)="openAuth('signUp', 'Sign up')"
        (keydown.enter)="openAuth('signUp', 'Sign up')"
        tabindex="0"
      >
        <span class="auth-button margin-none">Sign up</span>
        <stxt-svg-icon class="chat_right-svg" [iconConfig]="rightIcon"></stxt-svg-icon>
      </a>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="showProfileFlyout && showDropdown">
  <stxt-profile-flyout (closeDropdown)="openProfile($event)"></stxt-profile-flyout>
</ng-container>
