<div class="chat-wrapper">
  <!-- NOTE: THIS IS THE CHATS CONTAINER -->
  <div
    *ngIf="showChats"
    class="messages-sidebar"
    [@slideInOut]="showChats ? 'open' : 'closed'"
    [ngClass]="{ 'messages-full-screen': showChatsFullScreen }"
  >
    <div class="messages-sidebar_header flex justify-content-between items-center">
      <p>Messages</p>
    </div>
    <div *ngIf="isUserLoaded" class="messages-sidebar_body">
      <stxt-conversation-list
        [conversationsList]="[]"
        (click)="closeFullscreenMessages()"
        (keydown.enter)="closeFullscreenMessages()"
      ></stxt-conversation-list>
    </div>
  </div>

  <div class="chat">
    <div
      *ngIf="isCreatorDataExists"
      class="chat_header items-center flex"
      [ngClass]="{ 'friendship-open': isFriendshipOpen }"
    >
      <div class="chat_info flex items-center">
        <stxt-svg-icon
          [iconConfig]="backIcon"
          class="back-svg"
          (click)="backToChats()"
          (keydown.enter)="backToChats()"
        ></stxt-svg-icon>
        <div class="chat_contributors">
          <!-- * TODO -- Hidden for V1 since we won't allow users to upload pictires at this stage * -->
          <!-- <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" fill="#7B0072" r="20"/>
        </svg> -->
          <button class="btn-nostyle" (click)="openCreatorProfile()">
            <img [src]="creator.thumbnail_image" alt="creator" class="chat_creator__image" />
          </button>
        </div>
        <div class="chat_info">
          <button class="btn-nostyle" (click)="openCreatorProfile()">
            <div class="chat_info__name">
              <img src="assets/icons/verified.svg" alt="verified" class="name-badge" />
              <p class="">{{ creator.creator_name }}</p>
            </div>
          </button>
          <!--          TODO - hidden for v1-->
          <!--        <div class="flex chat_info__nav pointer items-center">-->
          <!--          <p>View friendship</p>-->
          <!--          <stxt-svg-icon [iconConfig]="rightIcon" class="chat_right-svg"></stxt-svg-icon>-->
          <!--        </div>-->
        </div>
      </div>
      <div class="chat_header__helpers">
        <stxt-svg-icon
          [iconConfig]="questionIcon"
          class="chat-svg"
          ngbTooltip="Get help"
          placement="bottom"
          tooltipClass="custom-tooltip"
          (click)="window.open(getHelpLink, '_blank')"
          (keydown.enter)="window.open(getHelpLink, '_blank')"
        ></stxt-svg-icon>
        <stxt-button
          *ngIf="!isFriendshipOpen && isFriendshipEnabled && showFriendshipText"
          [buttonConfig]="friendshipButtonConfig"
          (click)="toggleFriendshipDrawer()"
          (keydown.enter)="toggleFriendshipDrawer()"
          class="button-svg"
        >
          <stxt-svg-icon [iconConfig]="friendshipIcon" class="friendship-svg"></stxt-svg-icon>
          <p>Show friendship</p>
        </stxt-button>
        <stxt-svg-icon
          *ngIf="!isFriendshipOpen && isFriendshipEnabled && !showFriendshipText"
          [iconConfig]="friendshipIcon"
          (click)="toggleFriendshipDrawer()"
          (keydown.enter)="toggleFriendshipDrawer()"
          class="chat-svg"
        ></stxt-svg-icon>
      </div>
    </div>

    <div
      *ngIf="isUserLoaded && isCreatorDataExists"
      #messageContainer
      class="chat_body flex flex-column"
      [ngClass]="{
        'preview-mode_body': previewMode,
        'full-body-width': !isFriendshipEnabled,
        'friendship-open': isFriendshipOpen
      }"
      [style.height.px]="chatBodyHeight"
      (scroll)="onScroll($event)"
    >
      <div [@fade] class="chat_main flex flex-column">
        <ng-container>
          <div *ngFor="let messagePack of historyMessages" class="flex flex-column">
            <p *ngIf="messagePack.date" class="grouped_message__date">{{ messagePack.date }}</p>
            <stxt-message
              *ngFor="let message of messagePack.messages; let i = index; trackBy: trackByFn"
              [creatorId]="creatorId"
              [creatorName]="creator.creator_name"
              [userId]="userId"
              [messageItem]="message"
              [selectedMessageAudio]="selectedMessageAudio"
              [voiceSupported]="voiceSupported"
              (clicked)="setActive(i, message)"
              (isPreviewMode)="previewMode = $event"
              (triggerPayment)="openPayment(EPaymentType.Purchase, message)"
              (updatePlayMessageState)="trackSelectedVoice($event)"
              [ngClass]="{
                'active-sender': activeIndex === message.timestamp && message.from_stxt,
                'active-receiver': activeIndex === message.timestamp && !message.from_stxt
              }"
              class="chat_message"
            ></stxt-message>
          </div>
        </ng-container>
        <p *ngIf="messages.length > 0" class="grouped_message__date">Today</p>
        <stxt-message
          *ngFor="let message of messages; let i = index; trackBy: trackByFn"
          [messageItem]="message"
          [creatorId]="creatorId"
          [userId]="userId"
          [creatorName]="creator.creator_name"
          [selectedMessageAudio]="selectedMessageAudio"
          [voiceSupported]="voiceSupported"
          (clicked)="setActive(i, message)"
          (isPreviewMode)="previewMode = $event"
          (triggerPayment)="openPayment(EPaymentType.Purchase, message)"
          (updatePlayMessageState)="trackSelectedVoice($event)"
          [ngClass]="{
            'active-sender': activeIndex === message.timestamp && message.from_stxt,
            'active-receiver': activeIndex === message.timestamp && !message.from_stxt
          }"
          class="chat_message"
        ></stxt-message>
      </div>
      <div *ngIf="isTyping" class="typing-message">
        <div class="loader"></div>
      </div>
    </div>
    <div
      [ngClass]="{
        'preview-mode_footer': previewMode || !isUserLoaded,
        'full-footer-width': !isFriendshipEnabled,
        'friendship-open': isFriendshipOpen
      }"
      class="chat_footer"
    >
      <div *ngIf="isCannedResponses" class="chat_canned-messages">
        <p class="chat_canned-messages__head">Pick an option:</p>
        <div class="chat_canned-messages__main">
          <stxt-canned-message
            *ngFor="let cannedMessage of cannedMessages"
            [message]="cannedMessage"
            (click)="sendMessage(cannedMessage)"
            (keydown.enter)="sendMessage(cannedMessage)"
          ></stxt-canned-message>
        </div>
      </div>
      <ng-container *ngIf="!isCannedResponses && !isSubscription && isRequestPhotosEnabled">
        <stxt-conversation-feature
          [creatorData]="creatorData"
          (featureEvent)="handleFeatureEvent($event)"
        ></stxt-conversation-feature>
      </ng-container>

      <stxt-subscription-paywall
        *ngIf="isSubscription && creatorData.sub_plan"
        [creatorImg]="creator.thumbnail_image"
        [subscriptionPrice]="creatorData.sub_plan.price"
        (subscribeEvent)="openPayment(EPaymentType.Subscription)"
      ></stxt-subscription-paywall>
      <div
        [hidden]="isCannedResponses || isRequestPhoto"
        class="input-custom flex w-full items-center relative"
      >
        <span
          #inputMessage
          [style.height.px]="inputMessageHeight"
          (keydown)="onMessageInputChange($event)"
          (paste)="onPaste($event)"
          class="input-message"
          contenteditable="true"
        ></span>
        <span *ngIf="inputMessage.innerHTML.length === 0" class="placeholder-text">
          Type something...
        </span>
        <div
          [ngClass]="{
            'message-input_button__enabled': inputMessage.innerHTML.length > 0,
            'message-input_button__disabled': inputMessage.innerHTML.length === 0
          }"
          class="message-input_button absolute"
        >
          <stxt-svg-icon
            [iconConfig]="sendIcon"
            (pointerdown)="sendMessage(inputMessage.innerHTML)"
            class="password-eye"
          ></stxt-svg-icon>
        </div>
      </div>
    </div>
    <stxt-friendship-panel
      *ngIf="isFriendshipEnabled"
      [ngStyle]="{ display: isFriendshipOpen ? 'block' : 'none' }"
      [@slideInOut]="isFriendshipOpen ? 'open' : 'closed'"
      [hidden]="previewMode"
      [creatorData]="creatorData"
      [friendsSince]="friendsSince"
      [isFriendshipOpen]="isFriendshipOpen"
      (closeFriendshipPage)="toggleFriendshipDrawer($event)"
    ></stxt-friendship-panel>
  </div>
</div>

<ng-container *ngIf="isPayment && isCreatorDataExists">
  <stxt-payment
    [creatorId]="creatorId"
    [creator]="creator.creator_name"
    [paymentType]="paymentType"
    [subscriptionData]="creatorData.sub_plan"
    [purchaseData]="purchaseData"
    [wsConnectionId]="wsConnectionId"
    (closePaymentEvent)="closePayment($event)"
  ></stxt-payment>
</ng-container>

<ng-container *ngIf="!isUserLoaded && userId">
  <div class="loading_wrapper">
    <stxt-loading [loadingText]="ELoadingTexts.GetReadyToImagine"></stxt-loading>
  </div>
</ng-container>
