<div
  (click)="openChat($event)"
  (keydown.enter)="openChat($event)"
  class="discover-card relative"
  tabindex="-1"
>
  <div class="discover-card_media">
    <div class="image_wrapper">
      <img
        class="primary-image"
        [ngSrc]="discoverItem?.image"
        priority
        fill
        [alt]="discoverItem.name"
      />
      <img
        class="secondary-image"
        [ngSrc]="
          discoverItem?.secondary_image !== '' ? discoverItem?.secondary_image : discoverItem?.image
        "
        [alt]="discoverItem.secondary_image"
        fill
      />
    </div>
    <div class="additional_space"></div>
  </div>
  <div class="discover-card_nav absolute" [ngClass]="{ 'coming-soon': !discoverItem?.ready }">
    <ng-container *ngIf="discoverItem?.ready">
      <ng-container *ngTemplateOutlet="activeCreators"></ng-container>
    </ng-container>
  </div>
  <div class="discover-card_body absolute">
    <div class="discover-card_info">
      <div class="flex discover-card_head">
        <div class="discover-card_name relative">
          {{ truncate(discoverItem.name, 15) }}
          <span class="discover-card_ai absolute">
            <stxt-svg-icon class="verify-svg" [iconConfig]="verifySvg"></stxt-svg-icon>
          </span>
        </div>
      </div>
      <stxt-discover-card-badge *ngIf="!discoverItem?.ready"></stxt-discover-card-badge>
      <div *ngIf="discoverItem?.ready" class="discover-card_additional-info flex items-center">
        <div class="discover-card_additional-info__sfw flex">
          <p>
            <span class="discover-card_emoji">🔥</span>
            NSFW
          </p>
        </div>
        <span class="discover-card_divider">•</span>
        <div class="discover-card_additional-info__content flex">
          <div class="discover-card_additional-info__item flex">
            <p>
              <span class="discover-card_emoji">👤</span>
              {{ discoverItem.followers | numberCount }}
            </p>
          </div>
        </div>
      </div>
      <div class="discover-card_description__wrapper">
        <p class="discover-card_description__text">{{ discoverItem.description }}</p>
        <stxt-play-voice-button
          *ngIf="discoverItem?.voice_preview?.length"
          #previewVoice
          [audio]="discoverItem.voice_preview"
          [audioId]="discoverItem.name"
          [usageCase]="EPlayButtonUsage.DiscoverCard"
          (voicePlaying)="trackVoicePlaying($event)"
        ></stxt-play-voice-button>
      </div>
    </div>
  </div>
</div>

<ng-template #activeCreators>
  <div class="discover-card_coming-soon">
    <div class="coming-soon_svg__wrapper relative">
      <stxt-svg-icon
        class="coming-soon_svg clock-svg_outlined"
        [iconConfig]="chatSvg"
        [disabled]="isChatLoading"
        (click)="openChat($event)"
        (keydown.enter)="openChat($event)"
        tabindex="-1"
      ></stxt-svg-icon>
      <stxt-svg-icon
        class="coming-soon_svg clock-svg_hovered"
        [iconConfig]="chatFilledSvg"
        [disabled]="isChatLoading"
        (click)="openChat($event)"
        (keydown.enter)="openChat($event)"
        tabindex="-1"
      ></stxt-svg-icon>
    </div>
    <p>Chat</p>
  </div>
</ng-template>

<ng-template #comingSoonCreators>
  <div class="discover-card_coming-soon">
    <div class="coming-soon_svg__wrapper relative">
      <stxt-svg-icon
        class="coming-soon_svg clock-svg_outlined"
        [iconConfig]="clockSvg"
      ></stxt-svg-icon>
      <stxt-svg-icon
        class="coming-soon_svg clock-svg_hovered"
        [iconConfig]="clockFilledSvg"
      ></stxt-svg-icon>
    </div>
    <p>Notify me</p>
  </div>
</ng-template>
