<div class="purchases">
  <h4 class="purchases_heading">Purchases</h4>
  <ul ngbNav #nav="ngbNav" (navChange)="onTabChange($event)" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <button [ngClass]="{ 'active-tab': isActive(1) }" ngbNavLink>Subscriptions</button>
      <ng-template ngbNavContent>
        <ng-container *ngIf="subscriptionsList?.length; else noSubscriptions">
          <div class="flex flex-column gap-1">
            <stxt-purchases-subscription-card
              *ngFor="let subscription of subscriptionsList"
              [subscriptionItem]="subscription"
              (click)="openSubscriptionManagementEvent.emit(subscription)"
              (keydown.enter)="openSubscriptionManagementEvent.emit(subscription)"
              tabindex="-1"
            ></stxt-purchases-subscription-card>
          </div>
        </ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <button [ngClass]="{ 'active-tab': isActive(2) }" ngbNavLink>All transactions</button>
      <ng-template ngbNavContent>
        <ng-container *ngIf="oneTimePurchase.length; else noSubscriptions">
          <div class="flex flex-column mt-3 gap-3">
            <stxt-one-time-purchase-card
              *ngFor="let purchase of oneTimePurchase"
              [purchase]="purchase"
              (click)="openTransactionInfoEvent.emit(purchase)"
              (keydown.enter)="openTransactionInfoEvent.emit(purchase)"
              tabindex="-1"
            ></stxt-one-time-purchase-card>
          </div>
        </ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #noSubscriptions>
  <div class="no-subscription">
    <h4 class="no-subscription_heading">No purchases yet</h4>
    <p class="no-subscription_subheading">
      Your purchase history is empty, but it doesn’t have to be.
    </p>
    <img
      src="/assets/d2c-images/no_subscription.png"
      class="no-subscription_img"
      alt="no-subscription"
    />
    <stxt-button
      class="no-subscription_discover"
      [buttonConfig]="discoverBtnConfig"
      (click)="closeProfileEvent.emit()"
      (keydown.enter)="closeProfileEvent.emit()"
      tabindex="0"
    >
      {{ discoverBtnConfig.text }}
    </stxt-button>
  </div>
</ng-template>
