export enum EPaymentType {
  Purchase = 'purchase',
  Subscription = 'subscription',
  Billing = 'billing'
}

export enum EPaymentStatus {
  Success = 'success',
  Error = 'error',
  Close = 'close'
}

export enum EPaymentField {
  cardNumber = 'cardNumber',
  cardExpiry = 'cardExpiry',
  cardCvc = 'cardCvc',
  generic = 'generic'
}

export enum EPaymentErrorTitle {
  cardNumber = 'Invalid card number',
  cardExpiry = 'Invalid expiration date',
  cardCvc = 'Invalid CVV',
  generic = 'Your transaction could not be processed'
}

export enum EPaymentErrorMessage {
  cardNumber = 'Please enter a valid card number',
  cardExpiry = 'Please enter a valid expiration date',
  cardCvc = 'Please enter a valid CVV',
  generic = 'Please provide another payment method, or contact your bank provider and try again later.'
}
