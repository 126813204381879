import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { ChatListService } from '../../shared/services/chat-list.service';

import { ChatItemComponent } from '../chat-item/chat-item.component';

import { IConversation } from '../../interfaces/conversations.interfaces';

import { positionAnimations } from '../../shared/animations/chatListAnimation.animations';

@Component({
  selector: 'stxt-conversation-list',
  standalone: true,
  imports: [CommonModule, ChatItemComponent],
  templateUrl: './conversation-list.component.html',
  styleUrl: './conversation-list.component.scss',
  animations: [positionAnimations]
})
export class ConversationListComponent implements OnInit, OnDestroy {
  @Input() conversationsList: IConversation[];
  conversationSubscription: Subscription;

  constructor(readonly chatListService: ChatListService) {}

  ngOnInit(): void {
    this.conversationSubscription = this.chatListService.conversations$.subscribe(conversations => {
      this.conversationsList = conversations;
    });
  }

  trackById(index: number, conversation: IConversation): string {
    return `${conversation.conversation_id}-${index}`;
  }

  selectConversation(conversationId: string): void {
    this.chatListService.selectedConversationId.next(conversationId);
    this.chatListService.selectConversation(conversationId);
  }

  ngOnDestroy(): void {
    this.conversationSubscription.unsubscribe();
  }
}
