import { trigger, transition, query, animate, style } from '@angular/animations';

export const positionAnimations = [
  trigger('animatePosition', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ transform: 'translateY(-20px)', opacity: 0 }),
          animate('500ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
        ],
        { optional: true }
      ),
      query(
        ':enter, .conversation-item',
        [
          style({ position: 'relative' }),
          animate('500ms ease-in-out', style({ transform: 'translateY(0)' }))
        ],
        { optional: true }
      )
    ])
  ])
];
