<div *ngIf="subscriptionPrice" class="subscription-paywall flex items-center">
  <div class="flex subscription-paywall_main items-center">
    <img class="subscription-paywall_image" [src]="creatorImg" alt="creator-subscription" />
    <div class="subscription-paywall_info">
      <p class="subscription-paywall_heading">Keep chatting with me</p>
      <p class="subscription-paywall_subheading">
        {{ subscriptionPrice / 100 | currency }}/month. Risk free, cancel anytime.
      </p>
    </div>
  </div>
  <stxt-button
    class="subscription-paywall_btn"
    [buttonConfig]="subscribeBtn"
    (click)="subscribeEvent.emit()"
    (keydown.enter)="subscribeEvent.emit()"
  >
    {{ subscribeBtn.text }}
  </stxt-button>
</div>
