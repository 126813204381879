<div
  *ngIf="conversation.thumbnail_image?.length > 0"
  [ngClass]="{ active: conversation?.isSelected }"
  class="chat-item flex w-full items-center pointer"
>
  <div class="chat-item_photo flex">
    <ng-container *ngIf="conversation.thumbnail_image?.length">
      <img class="chat-item_photo-user" [src]="conversation.thumbnail_image" alt="creator-img" />
    </ng-container>
    <ng-container *ngIf="!conversation.thumbnail_image?.length">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <circle cx="28" cy="28" r="28" fill="#7B0072" />
      </svg>
    </ng-container>
  </div>
  <div class="chat-item_main flex">
    <div class="chat-item_main__info">
      <p class="chat-item_main__name relative">
        {{ conversation.creator_name }}
        <span class="chat-item_verify absolute">
          <stxt-svg-icon class="chat-item_verify-svg" [iconConfig]="verifySvg"></stxt-svg-icon>
        </span>
      </p>
      <p *ngIf="!conversation.isTyping" class="chat-item_main__message">
        {{ conversation?.last_message }}
      </p>

      <div *ngIf="conversation.isTyping" class="chat-item_main__message">
        <div class="typing-indicator"></div>
      </div>
    </div>
    <div class="chat-item_info flex flex-column">
      <p class="chat-item_info__date flex items-center">
        {{ formattedDate }}
      </p>
      <!--    TODO - NO needed for v1-->
      <!--    <div class="chat-item_info__new-message flex flex-column items-center w-fit-content">-->
      <!--      3-->
      <!--    </div>-->
    </div>
  </div>
</div>
