import { Component, Input } from '@angular/core';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IPaymentErrorMessage } from 'app/interfaces/payments.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EPaymentType } from 'app/enums/payments.enums';

@Component({
  selector: 'stxt-payment-failed',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './payment-failed.component.html',
  styleUrl: './payment-failed.component.scss'
})
export class PaymentFailedComponent {
  @Input() paymentType: EPaymentType;
  @Input() error: IPaymentErrorMessage;
  warningSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'info' };

  public closeSvg: ISvgConfig = { fill: ESvgTypes.Outlined, name: 'close' };
}
