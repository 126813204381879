<div [@fade] class="success-toast flex space-between">
  <div class="flex">
    <stxt-svg-icon [iconConfig]="checkVerifyIcon" class="success-toast_check-svg"></stxt-svg-icon>
    <div class="success-toast_body">
      <p class="success-toast_heading">{{ toastHeading }}</p>
      <p class="success-toast_subheading">{{ toastSubheading }}</p>
    </div>
  </div>
  <stxt-svg-icon
    [iconConfig]="closeIcon"
    (click)="closeToast.emit()"
    (keydown.enter)="closeToast.emit()"
    tabindex="-1"
    class="success-toast_close-svg"
  ></stxt-svg-icon>
</div>
